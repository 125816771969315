import { Card, CardContent, Typography } from '@mui/material';
import { DocumentData, DocumentReference, getDoc } from 'firebase/firestore';
import htmr from 'htmr';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IVideoData } from './IVideoData';

const VideoPlayer: React.FC<{
  docRef?: DocumentReference<DocumentData, DocumentData>;
  video: IVideoData | null;
  center?: boolean;
}> = ({ docRef = null, video = null, center = true }) => {
  const [videoData, setVideoData] = useState<IVideoData | null>(video);
  const { t } = useTranslation();

  useEffect(() => {
    setVideoData(video);
  }, [video]);

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        if (!docRef) {
          return console.warn('invalid docRef');
        }
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setVideoData(docSnap.data() as IVideoData);
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching video data:', error);
      }
    };

    fetchVideoData();
  }, [docRef]);

  if (!videoData) {
    return <div className="text-center text-accent">{t('Loading')}...</div>;
  }

  const width = video?.width
    ? isNaN(Number(video?.width))
      ? video?.width
      : Number(video?.width)
    : '100%';
  const height = video?.height
    ? isNaN(Number(video?.height))
      ? video?.height
      : Number(video?.height)
    : '100%';

  return (
    <div
      className={`flex flex-col ${center ? 'items-center justify-center' : ''} bg-futuristic text-white`}
      style={{
        width,
      }}
    >
      <Card className="w-full bg-gradient-to-br from-futuristic via-secondary to-accent shadow-2xl">
        <CardContent>
          {!videoData.title ? null : (
            <Typography
              variant="h4"
              component="h1"
              className="text-center mb-4"
            >
              {htmr(t(videoData.title))}
            </Typography>
          )}
          <div className="video-container w-full mb-4">
            {!videoData.videoUrl ? null : (
              <iframe
                className="w-full rounded-md"
                src={t(videoData.videoUrl)}
                title={t(videoData.title)}
                allowFullScreen
                style={{
                  height,
                }}
              />
            )}
          </div>
          {!videoData.description ? null : (
            <Typography variant="body1" className="text-center">
              {htmr(t(videoData.description))}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default VideoPlayer;
