import { Box, IconButton, Tooltip } from '@mui/material';

import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import React from 'react';
import { Image } from "./Tickets/Image";


const socialLinks = [
  {
    name: 'YouTube',
    color: 'red',
    icon: <YouTubeIcon />,
    url: 'https://www.youtube.com/@winfacilpremioshow',
  },
  {
    name: 'Facebook',
    color: '#3d66ff',
    icon: <FacebookIcon />,
    url: 'https://www.facebook.com/winfacilrd',
  },
  {
    name: 'Instagram',
    color: 'yellow',
    icon: (
      <Image
        width={20}
        src={require('../assets/icons/instagram-icon.png')}
        alt={'itg'}
      />
    ),
    url: 'https://www.instagram.com/winfacilrd/profilecard/?igsh=MTYyOTcxc2lnaGIy',
  },
  {
    name: 'TikTok',
    color: 'black',
    icon: (
      <Image
        src={require('../assets/icons/tikto-icon.png')}
        width={20}
        alt={'tiktok'}
      />
    ),
    url: 'https://www.tiktok.com/@winfacil?_t=ZP-8sGk8VJYTUc&_r=1',
  },
];

const SocialMediaLinks: React.FC = () => {
  return (
    <Box className={'flex justify-center mx-3 py-2'}>
      {socialLinks.map((item) => (
        <Tooltip key={item.name} title={item.name} arrow>
          <IconButton
            className="hover:scale-110 transition-transform"
            sx={{
              color: item.color,
            }}
            color={'primary'}
            component="a"
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Box>
  );
};

export default SocialMediaLinks;
