import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldVariants,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PasswordComponent = ({
  value,
  error,
  onChange,
  label,
  type,
  fullWidth,
  variant,
  className,
  setValue,
}: {
  type?: string;
  label?: string;
  value: string;
  error?: string;
  fullWidth?: boolean;
  variant?: TextFieldVariants;
  className?: string;
  onChange?:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  setValue?: (val: any) => void;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  return (
    <TextField
      label={t(label || 'Password')}
      type={type ? type : showPassword ? 'text' : 'password'}
      variant={variant || 'outlined'}
      fullWidth={typeof fullWidth === 'undefined' ? true : fullWidth}
      value={value}
      onChange={(...props) => {
        setValue && setValue(...props);
        onChange && onChange(...props);
      }}
      error={!!error}
      helperText={error}
      className={className || 'mt-4'}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  );
};

export default PasswordComponent;
