import { Clear, Email } from '@mui/icons-material';
import { Button, InputAdornment, TextField } from '@mui/material';

export function EmailComponent({
  setValue,
  label,
  value,
  disabled,
  full = true,
}: any) {
  return (
    <TextField
      disabled={disabled}
      label={label || 'Email'}
      type="email"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      fullWidth={full}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Email />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button variant="text" onClick={() => setValue('')}>
                <Clear />
              </Button>
            </InputAdornment>
          ),
        },
      }}
    />
  );
}
