import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import { format } from '@fragaria/address-formatter';
import { useTranslation } from 'react-i18next';
import { db } from '../firebase';
import { Image } from "./Tickets/Image";

const libraries: 'places'[] = ['places'];
const googleMapsApiKey = 'AIzaSyB-0ZOHboaPjqRhna396nH0-SO2fJe3iak'; //process.env.REACT_APP_GOOGLE_MAPS_API_KEY

interface UserAddress {
  city: string;
  country: string;
  county: string;
  houseNumber: number | null | string;
  postcode: string;
  province: string;
  street: string;
}

interface Country {
  currency: string;
  disabled: boolean;
  display: string;
  icon: string;
  order: number;
  code: string;
}

interface Props {
  onAddressSelect: (address: UserAddress, formattedAddress: string) => void;
  existingAddress?: UserAddress;
  setIsAddressUnsaved: (isUnsaved: boolean) => void;
}

const GoogleMapsAutoComplete: React.FC<Props> = ({
  onAddressSelect,
  existingAddress,
  setIsAddressUnsaved,
}) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [isManualInput, setIsManualInput] = useState<boolean>(false);
  const [manualAddress, setManualAddress] = useState<UserAddress>({
    city: '',
    country: '',
    county: '',
    houseNumber: null,
    postcode: '',
    province: '',
    street: '',
  });
  const [manualErrors, setManualErrors] = useState<UserAddress>({
    city: '',
    country: '',
    county: '',
    houseNumber: null,
    postcode: '',
    province: '',
    street: '',
  });
  const [validationError, setValidationError] = useState<string | null>(null);
  const [isAddressSubmitted, setIsAddressSubmitted] = useState<boolean>(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const { t } = useTranslation();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries,
  } as any);

  useEffect(() => {
    if (existingAddress) {
      setManualAddress(existingAddress);
    }

    // Fetch countries from Firestore
    const fetchCountries = async () => {
      try {
        const docRef = doc(db, 'app-configurations', 'countries');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const countriesData = docSnap.data();
          const countriesArray = Object.keys(countriesData)
            .map((key) => ({
              code: key,
              ...countriesData[key],
            }))
            .filter((country: Country) => !country.disabled)
            .sort((a: Country, b: Country) => a.order - b.order);

          setCountries(countriesArray);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, [existingAddress]);

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;

      if (addressComponents) {
        const userAddress: UserAddress = {
          city: getAddressComponent(addressComponents, 'locality'),
          country: getAddressComponent(addressComponents, 'country'),
          county: getAddressComponent(
            addressComponents,
            'administrative_area_level_2'
          ),
          houseNumber:
            parseInt(getAddressComponent(addressComponents, 'street_number')) ||
            null,
          postcode: getAddressComponent(addressComponents, 'postal_code'),
          province: getAddressComponent(
            addressComponents,
            'administrative_area_level_1'
          ),
          street: getAddressComponent(addressComponents, 'route'),
        };

        setInputValue(place.formatted_address || '');
        const formattedAddress = formatAddress(userAddress);
        onAddressSelect(userAddress, formattedAddress);
        setIsAddressSubmitted(true); // Mark address as submitted
        setIsAddressUnsaved(false); // Mark address as saved
      }
    }
  };

  const handleLoad = (
    autocompleteInstance: google.maps.places.Autocomplete
  ) => {
    setAutocomplete(autocompleteInstance);
  };

  const getAddressComponent = (
    components: google.maps.GeocoderAddressComponent[],
    type: string
  ) => {
    const component = components.find((comp) => comp.types.includes(type));
    return component ? component.long_name : '';
  };

  const handleManualAddressChange = (
    field: keyof UserAddress,
    value: string | number | null
  ) => {
    setManualAddress((prev) => ({ ...prev, [field]: value }));
    setManualErrors((prev) => ({ ...prev, [field]: '' })); // Clear field-specific error
    setIsAddressSubmitted(false); // Mark as not submitted
    setIsAddressUnsaved(true); // Mark as unsaved
  };

  const handleCountryChange = (event: any) => {
    const selectedCountry = event.target.value as string;
    setManualAddress((prev) => ({ ...prev, country: selectedCountry }));
    setIsAddressUnsaved(true); // Mark as unsaved
  };

  const validateManualAddress = () => {
    const newErrors: Partial<UserAddress> = {};

    if (!manualAddress.street) newErrors.street = t('Street is required');
    if (!manualAddress.houseNumber)
      newErrors.houseNumber = t('House number is required');
    if (!manualAddress.city) newErrors.city = t('City is required');
    if (!manualAddress.province) newErrors.province = t('Province is required');
    if (!manualAddress.country) newErrors.country = t('Country is required');
    // if (!manualAddress.postcode) newErrors.postcode = t("Postcode is required")
    // if (!manualAddress.county) newErrors.county = t("County is required")

    setManualErrors(newErrors as UserAddress);

    return Object.keys(newErrors).length === 0;
  };

  const formatAddress = (address: UserAddress): string => {
    const addressComponents = {
      road: address.street,
      house_number: address.houseNumber?.toString() || '',
      city: address.city,
      state: address.province,
      postcode: address.postcode,
      country: address.country,
      county: address.county,
    };

    return format(addressComponents, { output: 'string' });
  };

  const handleManualSubmit = () => {
    if (validateManualAddress()) {
      const formattedAddress = formatAddress(manualAddress);
      onAddressSelect(manualAddress, formattedAddress);
      setIsAddressSubmitted(true); // Mark address as submitted
      setIsAddressUnsaved(false); // Mark address as saved
      setValidationError(null);
    } else {
      setValidationError(t`Please fill out all required address fields.`);
    }
  };

  if (!isLoaded) return <div>{`${t('loading')}...`}</div>;

  return (
    <Box>
      {!isManualInput ? (
        <>
          <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceChanged}>
            <TextField
              label={t("address")}
              variant="outlined"
              fullWidth
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              autoComplete="off"
            />
          </Autocomplete>
          <Button
            variant="outlined"
            color="primary"
            className="mt-4"
            onClick={() => setIsManualInput(true)}
          >
            {t`Enter Address Manually`}
          </Button>
        </>
      ) : (
        <Box>
          <FormControl variant="outlined" fullWidth className="mb-4">
            <InputLabel id="country-select-label">{t`Country`}</InputLabel>
            <Select
              labelId="country-select-label"
              id="country-select"
              value={manualAddress.country}
              onChange={handleCountryChange}
              label={t('Country')}
            >
              {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  <div className="flex flex-row gap-1">
                    <Image
                      src={country.icon}
                      alt={country.display}
                      style={{ width: 24, height: 24, marginRight: 8 }}
                    />
                    <span> {country.display}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label={t('street')}
            required
            variant="outlined"
            fullWidth
            className="mb-4"
            value={manualAddress.street}
            onChange={(e) =>
              handleManualAddressChange('street', e.target.value)
            }
            error={!!manualErrors.street}
            helperText={manualErrors.street}
          />
          <TextField
            label={t('House Number')}
            required
            variant="outlined"
            fullWidth
            className="mb-4"
            value={manualAddress.houseNumber || ''}
            onChange={(e) =>
              handleManualAddressChange(
                'houseNumber',
                parseInt(e.target.value) || null
              )
            }
            error={!!manualErrors.houseNumber}
            helperText={manualErrors.houseNumber}
          />
          <TextField
            label={t('city')}
            required
            variant="outlined"
            fullWidth
            className="mb-4"
            value={manualAddress.city}
            onChange={(e) => handleManualAddressChange('city', e.target.value)}
            error={!!manualErrors.city}
            helperText={manualErrors.city}
          />
          <TextField
            label={t('Province')}
            required
            variant="outlined"
            fullWidth
            className="mb-4"
            value={manualAddress.province}
            onChange={(e) =>
              handleManualAddressChange('province', e.target.value)
            }
            error={!!manualErrors.province}
            helperText={manualErrors.province}
          />
          <TextField
            label="Postcode"
            variant="outlined"
            fullWidth
            className="mb-4"
            value={manualAddress.postcode}
            onChange={(e) =>
              handleManualAddressChange('postcode', e.target.value)
            }
            error={!!manualErrors.postcode}
            helperText={manualErrors.postcode}
          />
          <TextField
            label={t('county')}
            variant="outlined"
            fullWidth
            className="mb-4"
            value={manualAddress.county}
            onChange={(e) =>
              handleManualAddressChange('county', e.target.value)
            }
            error={!!manualErrors.county}
            helperText={manualErrors.county}
          />
          {validationError && (
            <Alert severity="error" className="mb-4">
              {validationError}
            </Alert>
          )}
          {!isAddressSubmitted && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className="mt-4"
              onClick={handleManualSubmit}
            >
              {t`Submit Address`}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default GoogleMapsAutoComplete;
