import { Button } from '@mui/material';
import { createContext, SetStateAction, useContext, useState } from 'react';
import LoadingScreen from 'react-loading-screen';

import { ModalPopup } from './PopupService';

export const LoadingService: {
  setLoading?: any;
  showLoading?: any;
  hide?: any;
  show?: any;
  hideLoading?: any;
} = {
  setLoading: null,
  hide: null,
  show: null,
  showLoading: null,
  hideLoading: null,
};
const ContextProps: any = null;
const LoadingContext = createContext(ContextProps);

export const useLoading = () => {
  return useContext(LoadingContext);
};

export const LoadingProvider = ({ children }: any) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');
  const [okToDismiss, setOkToDismiss] = useState(false);
  const [okCallback, setOkCallback] = useState(null);

  LoadingService.setLoading = (
    loading: boolean | ((prevState: boolean) => boolean),
    message: SetStateAction<string>,
    ok: null
  ) => {
    setVisible(loading);
    setValue(message);
    setOkToDismiss(!!ok);
    typeof ok === 'function' && setOkCallback(() => ok);
  };

  const hideLoading = () => {
    setVisible(false);
  };
  const showLoading = () => {
    setVisible(true);
  };
  LoadingService.hide = () => {
    hideLoading();
  };

  LoadingService.show = (message: string) => {
    setValue(message);
    showLoading();
  };

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading, okCallback }}>
      {!visible ? null : (
        <Loading
          okCallback={okCallback}
          message={value}
          visible={visible}
          setVisible={setVisible}
          setValue={setValue}
          setOkToDismiss={setOkToDismiss}
          okToDismiss={okToDismiss}
        />
      )}
      {children}
    </LoadingContext.Provider>
  );
};

export function Loading(props: any) {
  const {
    children,
    okCallback,
    message,
    setVisible,
    setValue,
    okToDismiss,
    setOkToDismiss,
    visible,
  } = props;
  if (message) {
    return (
      <ModalPopup
        modalVisible={visible}
        title=""
        setModalVisible={setVisible}
        type="success"
        popup={message}
        showOk={okToDismiss}
        onOk={okCallback}
      />
    );
  }

  return (
    <LoadingScreen
      loading={true}
      bgColor="#f1f1f180"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      logoSrc="logo512.png"
      text="Loading"
    >
      {children || <div />}
      {!!okToDismiss && (
        <Button
          onClick={() => {
            setVisible(false);
            setValue('');
            setOkToDismiss(false);
            okCallback && okCallback();
          }}
        >
          OK
        </Button>
      )}
    </LoadingScreen>
  );
}
