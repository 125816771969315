import { Button, Typography } from '@mui/material';

import { MyLocation } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from '../react-ui-components/components/location';
import { getLocationStr } from '../utils';

interface UserLocationButtonProps {
  style?: React.CSSProperties;
  textPosition?: string;
  iconColor?: any;
  iconStyle?: any;
  hideIcon?: boolean;
  color?: any;
  fullWidth?: boolean;
  onPress?: (location: any) => void;
  textButton?: boolean;
}

export const UserLocationButton = function (props: UserLocationButtonProps) {
  const {
    textPosition,
    iconColor,
    iconStyle,
    hideIcon,
    color,
    fullWidth,
    textButton,
    onPress,
  } = props;

  const { currentLocation } = useLocation();

  const { t } = useTranslation();

  const UserLocationButtonText = () => {
    const loc = { ...currentLocation };
    loc.country = t((loc.country || 'us').toLowerCase());
    return <Typography fontSize="smaller">{getLocationStr(loc)}</Typography>;
  };

  return (
    <Button
      variant={textButton ? 'text' : 'contained'}
      color={color || 'success'}
      fullWidth={fullWidth}
      onClick={() => onPress && onPress(currentLocation)}
    >
      {textPosition !== 'RIGHT' && UserLocationButtonText()}
      {!hideIcon && <MyLocation style={iconStyle} />}
      {textPosition === 'RIGHT' && UserLocationButtonText()}
    </Button>
  );
};
