import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from './LocationSelector';

export const CityLocationSelector = ({
  value,
  onChange,
  label,
  context,
}: any) => {
  const { t } = useTranslation();
  const { availableLocations, currentLocation, setCurrentLocation, loading } =
    useLocation(context);

  const [cities, setCities] = useState<string[]>([]);

  useEffect(() => {
    updateLocation(value);
  }, [value]);

  useEffect(() => {
    const country = currentLocation?.country || '';
    const state = currentLocation?.state || '';
    const arr = availableLocations?.[country]?.[state] || [];
    setCities(arr);
  }, [currentLocation]);

  function updateLocation(city: string) {
    const country = currentLocation?.country || '';
    const state = currentLocation?.state || '';
    const arr = availableLocations?.[country]?.[state] || [];
    const loc = {
      city: city || '',
      country: currentLocation.country || '',
      cities: currentLocation.cities,
      state,
      states: arr,
    };
    setCurrentLocation && setCurrentLocation(loc);
    setCities(arr);
  }

  const handleChange = (event: { target: { value: any } }, child: any) => {
    const value = event.target.value;
    updateLocation(value);

    onChange && onChange('city', value);
  };

  return (
    <div className="p-4">
      {loading ? (
        <CircularProgress />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <FormControl fullWidth className="mb-4" disabled={!cities?.length}>
            <InputLabel>{t(label || 'Select City')}</InputLabel>
            <Select
              value={currentLocation.city || ''}
              onChange={handleChange}
              displayEmpty
              renderValue={(value) =>
                value ? (
                  <div className="flex items-center gap-1">
                    <div>{t('' + value || 'Any')}</div>
                  </div>
                ) : (
                  <span>{t('Select City')}</span>
                )
              }
            >
              {!cities
                ? null
                : cities.map((val: any, i: number) => (
                    <MenuItem key={i} value={val}>
                      <div className="flex items-center gap-1">
                        <span> {t(val || 'Any')}</span>
                      </div>
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </motion.div>
      )}
    </div>
  );
};
