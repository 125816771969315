import React from "react"
import AppBar from "@mui/material/AppBar"
import { TopToolbar } from "./TopToolbar"
import { BottomToolbar } from "./BottomToolbar"

export const TopMenuComponent: React.FC<{
  drawerWidth: number
  open: boolean
  handleDrawerOpen?: React.MouseEventHandler<HTMLButtonElement>
  window?: () => Window
}> = ({ drawerWidth, handleDrawerOpen, open, window }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [isClosing, setIsClosing] = React.useState(false)

  const handleDrawerClose = () => {
    setIsClosing(true)
    setMobileOpen(false)
  }

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false)
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen)
    }
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <TopToolbar />
      <BottomToolbar open={open} handleDrawerOpen={handleDrawerOpen} />
    </AppBar>
  )
}

// const _AppBar = (drawerWidth: number) =>
//   styled(MuiAppBar, {
//     shouldForwardProp: (prop) => prop !== "open",
//   })<AppBarProps>(({ theme }) => ({
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     variants: [
//       {
//         props: ({ open }: any) => open,
//         position: "fixed",
//         style: {
//           width: `calc(100% - ${drawerWidth}px)`,
//           transition: theme.transitions.create(["margin", "width"], {
//             easing: theme.transitions.easing.easeOut,
//             duration: theme.transitions.duration.enteringScreen,
//           }),
//           marginLeft: drawerWidth,
//         },
//       },
//     ],
//   }))

// export const TopMenuComponent: React.FC<{
//   drawerWidth: number
//   open: boolean
//   handleDrawerOpen?: React.MouseEventHandler<HTMLButtonElement>
// }> = ({ drawerWidth, handleDrawerOpen, open }) => {
//   const AppBar: any = _AppBar(drawerWidth)
//   return (
//     <>
//       {/* <AppBar position="fixed" open={open}>
//         <Toolbar>
//           <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} component="div">
//             Persistent drawer
//           </Typography>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             edge="end"
//             onClick={handleDrawerOpen}
//             sx={[open && { display: 'none' }]}
//           >
//             <Menu />
//           </IconButton>
//         </Toolbar>
//       </AppBar> */}

//       <AppBar
//         open={open}
//         // position="fixed"
//         // sx={{
//         //   width: {
//         //     sm: `calc(100vw - ${drawerWidth}px)`,
//         //   },
//         //   ml: {
//         //     sm: `${drawerWidth}px`,
//         //   },
//         // }}
//       >
//         <Toolbar>
//           <div className="flex gap-1 justify-start w-full">
//             <Logo width={100} cropped />
//           </div>

//           <div className="flex flex-col w-full gap-1 items-end">
//             <UserLocation fullWidth textButton viewOnly color="secondary" />
//             <div className="flex flex-row gap-1">
//               <UsersTickets />
//               <UserPoints />
//             </div>
//           </div>
//         </Toolbar>

//         <Toolbar>
//           <div className="flex flex-row gap-1 mx-5 justify-between w-full">
//             <IconButton
//               color="inherit"
//               aria-label="open drawer"
//               edge="start"
//               onClick={handleDrawerOpen}
//               sx={[open && { display: "none" }]}
//             >
//               <MenuIcon />
//             </IconButton>

//             <HomeLink iconOnly />
//             <QRCodeScanner />
//             <VideoButton />
//             <MyLocationIcon onClick={() => PopupService.setPopup(<UserLocationSelector />)} />
//           </div>
//         </Toolbar>
//       </AppBar>
//     </>
//   )
// }
