import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useState } from 'react';

import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { LoadingService } from '../react-ui-components/components/LoadingService';

const ForgotPasswordPage = (props: any) => {
  const { close } = props;
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async () => {
    if (!email) {
      setError('Email is required');
      return;
    }

    LoadingService.setLoading(true);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(
        'Password reset email sent successfully. Check your email and follow the instructions.'
      );
      setError('');
    } catch (error) {
      setError('Error sending password reset email');
      setMessage('');
    } finally {
      LoadingService.setLoading(
        true,
        'Password reset email sent successfully. Check your email and follow the instructions.',
        close
      );
      //   setTimeout(() => {
      //     close && close()
      //   }, 5000)
    }
  };

  return (
    <Container maxWidth="xs">
      {/* <CssBaseline /> */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Forgot Password
        </Typography>
        {message && <Typography color="success.main">{message}</Typography>}
        {error && <Typography color="error.main">{error}</Typography>}
        <TextField
          label="Email"
          variant="outlined"
          margin="normal"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!error}
        />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handlePasswordReset}
          sx={{ mt: 2 }}
        >
          Send Password Reset Email
        </Button>
      </Box>
    </Container>
  );
};

export default ForgotPasswordPage;
