import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles";
import { useUserStore } from "./components/stores/userStore"

export const Provider: React.FC<{ children: any }> = ({ children }) => {
  
  const { theme } = useUserStore()


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
