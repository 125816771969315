import { AppStoreButton, GooglePlayButton } from 'react-mobile-app-button';

import { Download } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useUserStore } from './stores/userStore';
import { Image } from "./Tickets/Image";

const AndroidLogo = require('../images/android-logo.svg');
const AppleLogo = require('../images/apple-logo.svg');

interface IDownloadItem {
  width?: number | string;
  height?: number | string;
  style?: { [key: string]: any };
  className?: string;
}
interface ILogo extends IDownloadItem {
  cropped?: boolean;
}
export const Logo: React.FC<ILogo> = (props) => {
  const { width = 200, height, style, className, cropped } = props;
  return (
    <div className={className}>
      <Image
        src={
          cropped
            ? require('../assets/LogoWBox.png')
            : require('../images/logo.png')
        }
        style={style}
        width={width as any}
        height={height as any}
        alt="winfacil-logo"
      />
    </div>
  );
};

interface IDownloadItemEx extends IDownloadItem {
  revertColor?: boolean;
}
export const DownloadLogo: React.FC<IDownloadItemEx> = (props) => {
  const { t } = useTranslation();
  const { width = 150, height, style, revertColor } = props;
  return (
    <div
      style={{ position: 'relative', borderRadius: 90 }}
      className="flex overflow-hidden m-1"
    >
      <Image
        src={require('../assets/download-button.jpg')}
        style={{ minWidth: width, objectFit: 'contain', ...style }}
        width={width as any}
        height={height as any}
        alt="winfacil-download-logo"
      />
      <Typography
        color={revertColor ? 'secondary' : undefined}
        style={{ textTransform: 'none', position: 'absolute', left: '34%' }}
        className="flex self-center"
      >
        {t('download')}
      </Typography>
    </div>
  );
};

export const AppleDownload: React.FC<IDownloadItem> = (props) => {
  const { width = 20, height } = props;
  return (
    <Row cols={2}>
      <Image
        src={AppleLogo}
        width={width as any}
        height={height as any}
        alt="android-store-logo"
      />
      <Download />
    </Row>
  );
};

export const AndroidDownload: React.FC<IDownloadItem> = (props) => {
  const { width = 20, height } = props;
  return (
    <Row cols={2}>
      <Image
        src={AndroidLogo}
        width={width as any}
        height={height as any}
        alt="android-store-logo"
      />
      <Download />
    </Row>
  );
};

const APKUrl =
  'https://firebasestorage.googleapis.com/v0/b/winfacil-250ad.appspot.com/o/app%2Fapp-release-0.54.0.apk?alt=media&token=537a1fef-8de2-43e2-a13e-8160bd6d2213'; //require("../app-release-0.54.0.apk")
const IOSUrl = 'https://apps.apple.com/us/app/winfacil/id6443819589';

export const DownloadButtons: React.FC<IDownloadItemEx> = ({ revertColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userIsAdvanced } = useUserStore();
  const open = Boolean(anchorEl);

  const handleClick = (event: { currentTarget: any }) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        sx={{
          fontSize: 15,
        }}
        size="small"
        color="inherit"
        onClick={handleClick}
      >
        {/* <Logo style={{ width: 130, objectFit: "contain" }} /> */}
        <DownloadLogo revertColor={revertColor} />
        {/* <Download /> */}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem>
          <AppStoreButton
            url={IOSUrl}
            theme={'light'}
            className={'custom-style'}
          />
        </MenuItem>
        {userIsAdvanced ? (
          <MenuItem>
            <GooglePlayButton
              url={APKUrl}
              theme={'light'}
              className={'custom-style'}
            />
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};
