import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import React, { ReactNode, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { db } from '../firebase';
import { getCountryInfo } from '../utils';

interface LocationData {
  [country: string]: {
    [state: string]: string[];
  };
}

const CountryDropdown = styled(Select)`
  & .MuiSelect-select {
    display: flex;
    align-items: center;
  }
`;

const LocationSelector: React.FC<{
  onChange: (name: string, value: string) => void;
  userSelectedCountry?: string;
  userSelectedState?: string;
  userSelectedCity?: string;
}> = ({
  onChange,
  userSelectedCountry,
  userSelectedState,
  userSelectedCity,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [locations, setLocations] = useState<LocationData>({});
  const [countries, setCountries] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>(
    userSelectedCountry || ''
  );
  const [selectedState, setSelectedState] = useState<string>(
    userSelectedState || ''
  );
  const [selectedCity, setSelectedCity] = useState<string>(
    userSelectedCity || ''
  );
  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [emoji, setEmoji] = useState('');

  useEffect(() => {
    setSelectedCountry(userSelectedCountry || 'US');

    setSelectedState(userSelectedState || '');

    setSelectedCity(userSelectedCity || '');

    if (userSelectedCountry && locations?.[userSelectedCountry]) {
      setStates(
        userSelectedCountry
          ? Object.keys(locations[userSelectedCountry]).sort()
          : []
      );
    }

    if (
      userSelectedCountry &&
      userSelectedState &&
      locations?.[userSelectedCountry]?.[userSelectedState]
    ) {
      setCities(
        userSelectedState
          ? locations[userSelectedCountry][userSelectedState].sort()
          : []
      );
    }
  }, [userSelectedCountry, userSelectedState, userSelectedCity, locations]);

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true);
      try {
        const docRef = doc(db, 'app-configurations/availableLocations');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data() as LocationData;
          setLocations(data);
          setCountries(Object.keys(data).sort());
        }
      } catch (error) {
        console.error('Error fetching locations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLocations();
  }, []);

  const handleCountryChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ): any => {
    const country = event.target.value as string;
    setSelectedCountry(country);
    setSelectedState('');
    setSelectedCity('');
    setStates(country ? Object.keys(locations[country]).sort() : []);
    setCities([]);

    onChange('country', country);
  };

  const handleStateChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    const state = event.target.value as string;
    setSelectedState(state);
    setSelectedCity('');
    setCities(state ? locations[selectedCountry][state].sort() : []);
    onChange('state', state);
  };

  const handleCityChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    const city: string = event.target.value as string;
    setSelectedCity(city);
    onChange('city', city);
  };

  return (
    <div className="p-4">
      {loading ? (
        <CircularProgress />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <FormControl fullWidth className="mb-4">
            <InputLabel>{t('Select Country')}</InputLabel>
            <CountryDropdown
              value={selectedCountry}
              onChange={handleCountryChange}
              displayEmpty
              renderValue={(value) =>
                value ? (
                  <div className="flex items-center gap-1">
                    <div>{getCountryInfo(value as string).emoji}</div>
                    <div>{t('' + value)}</div>
                  </div>
                ) : (
                  <span>{t('Select Country')}</span>
                )
              }
            >
              {countries.map((country) => (
                <MenuItem key={country} value={country}>
                  <div className="flex items-center gap-1">
                    {/* <Image src={getCountryInfo(country)} alt="flag" className="w-5 h-5 mr-2" /> */}
                    <span> {getCountryInfo(country)?.emoji || '🌍'}</span>
                    <span> {t(country)}</span>
                  </div>
                </MenuItem>
              ))}
            </CountryDropdown>
          </FormControl>

          <FormControl fullWidth className="mb-4" disabled={!selectedCountry}>
            <InputLabel>{t('Select State')}</InputLabel>
            <Select
              value={selectedState}
              onChange={handleStateChange}
              displayEmpty
            >
              <MenuItem value="">{t('Select State')}</MenuItem>
              {states.map((state) => (
                <MenuItem key={state} value={state}>
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth disabled={!selectedState}>
            <InputLabel>{t('Select City')}</InputLabel>
            <Select
              value={selectedCity}
              onChange={handleCityChange}
              displayEmpty
            >
              <MenuItem value="">{t('Select City')}</MenuItem>
              {cities.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </motion.div>
      )}
    </div>
  );
};

export default LocationSelector;
