import {
  CitySelectWidget,
  CountrySelectWidget,
  StateSelectWidget,
} from '../widgets/LocationWidget';

export const locationUISchema = {
  city: { 'ui:widget': CitySelectWidget() },
  country: { 'ui:widget': CountrySelectWidget() },
  province: { 'ui:widget': StateSelectWidget() },
};
