import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as RoutesBase
} from "react-router-dom";

import App from "./App";
import { BusinessAds } from "./components/BusinessAds";
import Home from "./components/Home";
import ScrollToAnchor from "./ScrollToAnchor";
import Tickets from "./components/Tickets";
import Videos from "./components/video/Videos";
import { Sponsors } from "./components/Sponsors";
import { Login } from "./components/LoginScreen/Login";

export const Routes = () => {
  return (
     <RoutesBase>
      <Route path="" element={<App />}>
        <Route path="videos" element={<Videos />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="tickets" element={<Tickets />} />
        <Route path="sponsors" element={<Sponsors />} />
      </Route>
      <Route path="ads/:i" element={<BusinessAds />} />
    </RoutesBase>
  );
};
