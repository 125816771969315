import { Password, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';

export function PasswordComponent({
  setValue,
  label,
  value,
  disabled,
  full = true,
}: any) {
  const [showPass, setShowPass] = useState(false);

  return (
    <TextField
      disabled={disabled}
      label={label || 'Password'}
      type={showPass ? 'text' : 'password'}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      fullWidth={full}
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <Password />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button onClick={() => setShowPass((v) => !v)}>
                {showPass ? <VisibilityOff /> : <Visibility />}
              </Button>
            </InputAdornment>
          ),
        },
      }}
    />
  );
}
