const firebaseErrorMessages: { [key: string]: string } = {
  'auth/email-already-in-use':
    'This email is already registered. Please try logging in or use a different email.',
  'auth/invalid-email':
    'The email address is not valid. Please enter a correct email address.',
  'auth/weak-password':
    'The password is too weak. Please use at least 6 characters.',
  'auth/wrong-password':
    'The password is incorrect. Please try again or reset your password.',
  'auth/user-not-found':
    'No account found with this email. Please check your email or sign up.',
  'auth/too-many-requests':
    'Too many login attempts. Please wait a moment and try again.',
  'auth/network-request-failed':
    'Network error. Please check your internet connection and try again.',
  'auth/internal-error': 'An internal error occurred. Please try again later.',
};

export const getFriendlyFirebaseError = (errorCode: string, t: any): string => {
  // Extract error code from the full error message
  const code = errorCode.replace('Firebase: Error (', '').replace(').', '');
  const result =
    firebaseErrorMessages[code] ||
    'An unexpected error occurred. Please try again later.';

  return t(result);
};
