import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material"
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  User,
  sendEmailVerification,
  updateEmail,
  updatePassword,
  updatePhoneNumber,
} from "firebase/auth"
import { useEffect, useState } from "react"
import { phoneNumberMatch, validatePhoneNumber } from "../../utils"

import { IUser } from "../stores/IUser"

import { PopupService } from "../../react-ui-components/components/PopupService"

import { motion } from "framer-motion"
import { useAuthState } from "react-firebase-hooks/auth"
import { useTranslation } from "react-i18next"
import { auth } from "../../firebase"
import { useUserStore } from "../stores/userStore"

import { EmailComponent } from "../../react-ui-components/components/EmailComponent"
import { LoadingService } from "../../react-ui-components/components/LoadingService"
import PasswordComponent from "../PasswordComponent"
import { MuiPhone } from "../MuiPhone"

export function AccountInfo(props: {
  imagePreview: string | undefined
  isSmallScreen: boolean
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  updatedProfile: IUser | null | undefined
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onCancel: () => void
  handleSave: () => Promise<void>
  cropDialogOpen: boolean
  handleCropDialogClose: () => void
  crop: { x: number; y: number }
  zoom: number
  setCrop: React.Dispatch<React.SetStateAction<{ x: number; y: number }>>
  setZoom: React.Dispatch<React.SetStateAction<number>>
  onCropComplete: (croppedArea: any, croppedAreaPixels: any) => void
  handleCropDialogSave: () => Promise<void>
  updateUser: any
}) {
  const { user: profile } = useUserStore()
  const [user, loading]: any[] = useAuthState(auth)
  const [verificationId, setVerificationId] = useState<string>("")
  const { updatedProfile, handleChange, onCancel, updateUser } = props
  const [verificationCode, setVerificationCode] = useState("")
  const [phoneNumber, setPhoneNumber] = useState<string>(updatedProfile?.phoneNumber || "")
  const [newValidPhoneNumber, setNewValidPhoneNumber] = useState(false)
  const [countryCode, setCountryCode] = useState("")
  const [hasPhoneProvider, setHasPhoneProvider] = useState(false)
  const [newEmail, setNewEmail] = useState(updatedProfile?.email || "")
  const [password, setPassword] = useState("")
  const [emailVerified, setEmailVerified] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const isValidEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  const isValidPassword = (password: string) => password.length >= 6
  const isValidPhone = (phone: string) => /^\+?[0-9\s]*$/.test(phone)
  const [confirmPassword, setConfirmPassword] = useState("")

  const { t } = useTranslation()

  useEffect(() => {
    const result = user?.providerData?.find((x: any) => x.providerId === "phone")
    // console.log("user?.providerData----->", user?.providerData)
    setHasPhoneProvider(!!result)
  }, [user])

  useEffect(() => {
    // console.log("phoneNumber---->", phoneNumber)
    // console.log("updatedProfile?.phoneNumber---->", updatedProfile?.phoneNumber)
    const result = !!phoneNumber
      ? validatePhoneNumber(phoneNumber, countryCode.toUpperCase() as any)
      : null
    const phonesMatch = phoneNumberMatch(phoneNumber, updatedProfile?.phoneNumber || "").match
    setNewValidPhoneNumber(!!result?.valid && !phonesMatch)
  }, [phoneNumber, countryCode, updatedProfile?.phoneNumber])

  const handleUpdateEmail = async () => {
    if (updatedProfile?.email) {
      /*
          Updates the user's email address.
    
    @remarks
    An email will be sent to the original email address (if it was set) that allows to revoke the email address change, in order to protect them from account hijacking.
    
    This method is not supported on any User signed in by Auth instances created with a @firebase/app#FirebaseServerApp.
    
    Important: this is a security sensitive operation that requires the user to have recently signed in. If this requirement isn't met, ask the user to authenticate again and then call reauthenticateWithCredential.
          */
      const user: User = auth.currentUser as any

      try {
        LoadingService.setLoading(true)
        if (user) {
          if (!user.emailVerified) {
            PopupService.pushPopup("Please verify your email first.")
            await sendVerificationEmail(newEmail)
            PopupService.pushPopup("Verification email sent! Please check your inbox.")
          } else {
            await updateEmail(user, newEmail)
            PopupService.pushPopup("Email updated successfully!")
            setNewEmail("")
          }
        } else {
          PopupService.pushPopup("No user is currently signed in.")
        }
      } catch (error: any) {
        PopupService.pushPopup(`Failed to update email: ${error.message}`)
      } finally {
        LoadingService.setLoading(false)
      }

      try {
        await updateEmail(user, updatedProfile?.email)
      } catch (err: any) {
        const errorMessage = getFriendlyErrorMessage(err.code)
        PopupService.pushPopup(errorMessage, t("Error"))
      }
    }
    await updateUser(updatedProfile)
  }

  // const reauthenticate = async (password: string) => {
  //   const user: User = auth.currentUser as any
  //   const credential = EmailAuthProvider.credential(user.email, password)
  //   try {
  //     await reauthenticateWithCredential(user, credential)
  //   } catch (error: any) {
  //     PopupService.pushPopup(`Reauthentication failed: ${error.message}`)
  //   }
  // }

  // Step 1: Send verification email to new email address
  const sendVerificationEmail = async (email: string) => {
    try {
      const verificationMessage = await user.sendEmailVerification()
      return verificationMessage
    } catch (error: any) {
      PopupService.pushPopup(`Failed to send verification email: ${error.message}`)
      return null
    }
  }

  const getFriendlyErrorMessage = (code: string): string => {
    switch (code) {
      case "auth/invalid-email":
        return t("The email address is invalid. Please enter a valid email.")
      case "auth/email-already-in-use":
        return t("This email is already in use. Please try a different one.")
      case "auth/weak-password":
        return t("The password is too weak. Please use at least 6 characters.")
      case "auth/requires-recent-login":
        return t(
          "Sensitive action requires a recent login. Please log in again to update your information.",
        )
      case "auth/user-not-found":
        return t("User not found. Please check your details and try again.")
      case "auth/invalid-phone-number":
        return t("The phone number is invalid. Please enter a valid phone number.")
      default:
        return t("An error occurred. Please try again.")
    }
  }

  const updateUsersPassword = async () => {
    if (!isValidPassword(password)) {
      PopupService.pushPopup(t("Password must be at least 6 characters long"), t("Error"))
      return
    }

    if (password !== confirmPassword) {
      PopupService.pushPopup(t("Passwords do not match"), t("Error"))
      return
    }

    try {
      const user = auth.currentUser
      if (user) {
        await updatePassword(user, password)
        PopupService.pushPopup(t("Password updated successfully!"), "success")
      }
    } catch (err: any) {
      const errorMessage = t(getFriendlyErrorMessage(err.code))
      PopupService.pushPopup(errorMessage, t("Error"))
      setError(errorMessage)
    }
  }

  const handleSavePhoneNumber = async () => {
    try {
      LoadingService.setLoading(true)
      const data = {
        phoneNumber,
      }
      updateUser(data)

      handleChange({
        target: {
          name: "phoneNumber",
          value: phoneNumber,
        },
      } as any)
    } catch (error: any) {
      PopupService.setPopup(error.message)
    } finally {
      LoadingService.setLoading(false)
    }
  }

  const validatePhoneCode = async () => {
    // Obtain the verificationCode from the user.
    const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode)
    await updatePhoneNumber(user, phoneCredential)
  }

  const handleValidatePhoneNumber = async () => {
    // 'recaptcha-container' is the ID of an element in the DOM.
    const applicationVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "normal", // or 'invisible' based on your needs
      callback: (response: any) => {
        // reCAPTCHA solved - allow the user to send the OTP
        console.log("reCAPTCHA solved", response)
      },
      "expired-callback": () => {
        // Handle expiration
        console.log("reCAPTCHA expired")
      },
    })
    const provider = new PhoneAuthProvider(auth)
    const id = await provider.verifyPhoneNumber(phoneNumber, applicationVerifier)
    setVerificationId(id)
  }

  const sendVerificationUserEmail = async () => {
    try {
      const user = auth.currentUser
      if (user) {
        await sendEmailVerification(user)
        PopupService.pushPopup(t("Verification email sent. Please check your email."), "info")
      }
    } catch (err: any) {
      const errorMessage = getFriendlyErrorMessage(err.code)
      PopupService.pushPopup(errorMessage, "error")
    }
  }

  const updateUsersEmail = async () => {
    const email = newEmail

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      PopupService.pushPopup(t("Invalid email format"), "error")
      return
    }

    if (!email) {
      return
    }
    if (!isValidEmail(email)) {
      PopupService.pushPopup(t(t("Invalid email format")), t("Error"))
      return
    }

    try {
      const user = auth.currentUser
      if (user) {
        await sendVerificationUserEmail()

        await updateEmail(user, email)
        await updateUser({ email })
        PopupService.pushPopup(t("Email updated successfully!"), t("Success"))
      }
    } catch (err: any) {
      const errorMessage = t(getFriendlyErrorMessage(err.code))
      PopupService.pushPopup(errorMessage, t("Error"))

      // setError(err.message)
      // PopupService.pushPopup(t("Failed to update email: ") + err.message, "error")
    }
  }
  const MotionDiv = motion.div
  return (
    <MotionDiv
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      // className="flex justify-center items-center bg-gray-100"
    >
      <Container maxWidth="sm" className="mb-6">
        <Card sx={{ maxWidth: 345, mx: "auto", mt: 4 }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {t("editAccount")}
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                border: "1px solid #CACACA",
                borderRadius: 2,
                padding: 1,
                m: 2,
              }}
              className="flex flex-col"
            >
              {/* {error && (
                    <Typography color="error" className="mb-4">
                      {error}
                    </Typography>
                  )} */}

              <EmailComponent
                label={t("profile.email")}
                value={newEmail}
                setValue={(val: string) => setNewEmail(val)}
                disabled
              />

              <Button disabled variant="contained" color="primary" onClick={updateUsersEmail}>
                {t("Update Email")}
              </Button>
            </Box>

            {verificationId ? (
              <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                <TextField
                  margin="normal"
                  fullWidth
                  label={t("verificationCode")}
                  name="verificationCode"
                  value={verificationCode}
                  onChange={({ target }) => setVerificationCode(target.value)}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <Button variant="contained" color="primary" onClick={validatePhoneCode}>
                          {t("send")}
                        </Button>
                      ),
                    },
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  border: "1px solid #CACACA",
                  borderRadius: 2,
                  padding: 1,
                  m: 2,
                }}
              >
                <FormLabel>{t("profile.phoneNumber")}</FormLabel>
                <div id="recaptcha-container" >
                  <MuiPhone
                    // alwaysDefaultMask
                    // showDropdown
                    value={phoneNumber}
                    onChange={(phone, inputVal, country) => {
                      setCountryCode(country.dialCode)
                      // console.log("PhoneInput---->", { phone, option, e, format })
                      setPhoneNumber(phone)
                    }}
                    // inputStyle={{ width: "100%" }}
                    // containerStyle={{ width: "100%", marginTop: 16 }}
                  />
                </div>

                {!newValidPhoneNumber ? null : hasPhoneProvider ? (
                  <Button variant="contained" color="primary" onClick={handleValidatePhoneNumber}>
                    {t("profile.validatePhone")}
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleSavePhoneNumber}>
                    {t("Update Phone")}
                  </Button>
                )}
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                border: "1px solid #CACACA",
                borderRadius: 2,
                padding: 1,
                m: 2,
              }}
            >
              <PasswordComponent
                label={t("Password")}
                type="password"
                fullWidth
                variant="outlined"
                className="mb-4"
                value={password}
                setValue={(val: string) => setPassword(val)}
              />
              <PasswordComponent
                label={t("Confirm Password")}
                type="password"
                fullWidth
                variant="outlined"
                className="mb-4"
                value={confirmPassword}
                setValue={(val: string) => setConfirmPassword(val)}
              />
              <Button
                variant="contained"
                color="primary"
                className="w-full mb-4"
                onClick={updateUsersPassword}
                disabled={loading}
              >
                {t("Update Password")}
              </Button>
            </Box>
          </CardContent>
          <Box sx={{ display: "flex", justifyContent: "end", p: 2 }}>
            <Button variant="contained" color="secondary" onClick={onCancel}>
              {t("profile.cancel")}
            </Button>
          </Box>
        </Card>
      </Container>
    </MotionDiv>
  )
}
