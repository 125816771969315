import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { db } from './firebase';

const googleAPIKey = process.env.REACT_APP_GOOGLE_GEO_API_KEY;

const DEFAULT_COUNTRY = 'United States';

const CountrySelector: React.FC<{
  onChange?: any;
  currentCountry?: string;
  useCode?: boolean;
}> = ({ onChange, currentCountry, useCode }: any) => {
  const { t } = useTranslation();

  const [availableCountries, setAvailableCountries] = useState([
    {
      currency: 'DOP',
      key: 'DO',
      disabled: false,
      display: 'Dominican Republic',
      icon: 'https://firebasestorage.googleapis.com/v0/b/winfacil-250ad.appspot.com/o/assets%2Fdo_%402x.png?alt=media&token=ceba3828-184c-4036-9ef5-387990b15668',
      order: 1,
    },
    {
      currency: 'USD',
      disabled: false,
      display: DEFAULT_COUNTRY,
      icon: 'https://firebasestorage.googleapis.com/v0/b/winfacil-250ad.appspot.com/o/assets%2Fus-flag.jpeg?alt=media&token=b86edf51-856a-458f-b491-8f05bd979e7e',
      key: 'US',
    },
  ]);

  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const [code, setCode] = useState('US');

  useEffect(() => {
    async function getCountries() {
      const querySnapshot = doc(db, 'app-configurations/countries');

      const docRef = await getDoc(querySnapshot);

      const kv: any = docRef.data();

      setAvailableCountries(
        Object.keys(kv).map((k) => ({
          ...kv[k],
          key: k,
        }))
      );
    }

    getCountries();
  }, []);

  useEffect(() => {
    if (!currentCountry) {
      detectCurrentCountry(setCountry);
    } else {
      if (useCode) {
        setCountry(
          availableCountries.find((c) => c.key === currentCountry)?.display ||
            DEFAULT_COUNTRY
        );
        setCode(currentCountry);
      } else {
        setCountry(currentCountry);
      }
    }
  }, [availableCountries, currentCountry, useCode]);

  const handleLanguageChange = (event: any) => {
    setCountry(event.target.value);
    onChange && onChange(event);
  };

  return (
    <Box sx={{ minWidth: 120, marginTop: 2, width: '100%' }}>
      <FormControl fullWidth>
        <InputLabel id="language-selector-label">
          {t('profile.country')}
        </InputLabel>
        <Select
          name="country"
          className="flex flex-column gap-10"
          label={t('profile.language')}
          labelId="language-selector-label"
          value={useCode ? code : country}
          onChange={handleLanguageChange}
        >
          {availableCountries.map(({ key, display }, i) => (
            <MenuItem key={i} value={useCode ? key : display}>
              {t(display)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CountrySelector;

let _LOADED_COUNTRY_INFO: {
  short_name: string;
  long_name: string;
} | null = null;

export const detectCurrentCountry = (
  onFoundCountry: (code: string, props?: any) => void
) => {
  if (_LOADED_COUNTRY_INFO) {
    onFoundCountry &&
      onFoundCountry(_LOADED_COUNTRY_INFO.short_name, _LOADED_COUNTRY_INFO);
    return _LOADED_COUNTRY_INFO;
  }

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      try {
        const { latitude, longitude } = position.coords;
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleAPIKey}`
        );
        const data = await response.json();

        if (data.results.length > 0) {
          const countryComponent = data.results[0].address_components.find(
            (component: any) => component.types.includes('country')
          );

          if (countryComponent) {
            _LOADED_COUNTRY_INFO = countryComponent;
            const countryCode = countryComponent.short_name;
            onFoundCountry && onFoundCountry(countryCode, countryComponent);
          }
        }
      } catch (error) {
        console.error('Error detecting country:', error);
      }
    });
  }
};
