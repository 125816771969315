import { Button, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { LazyLoadImage, trackWindowScroll } from "react-lazy-load-image-component"

import Carousel from "react-material-ui-carousel"
import { PopupService } from "../react-ui-components/components/PopupService"
import { ISponsor } from "./stores/Sponsor"
import { ISponsorOffers } from "./stores/SponsorOffers"

import { useTranslation } from "react-i18next"
import { useLocation } from "../react-ui-components/components/location/LocationSelector"
import { colors } from "../theme"
import SponsorServices from "./SponsorServices"
import { SponsorOfferImage } from "./stores/SponsorOfferImage"
import { useUserStore } from "./stores/userStore"
import { Image } from "./Tickets/Image"

export interface SponsorsProps {
  style?: any
  scrollPosition?: any
  hideDiv?: boolean
  centerTitle: boolean
}

export const SponsorsList: React.FC<SponsorsProps> = (props) => {
  const { scrollPosition, hideDiv, centerTitle } = props
  const { t } = useTranslation()

  const { currentLocation: location } = useLocation()

  const { sponsors, displayContentConfig, listenForSponsorsChanges } = useUserStore()

  useEffect(() => {
    return listenForSponsorsChanges(location)
  }, [location])

  return (
    <div style={$container(hideDiv, centerTitle)} id="sponsors-content">
      <Typography variant="h6" fontSize="smaller" className="mb-5">
        {t(
          (
            displayContentConfig?.sponsorsTitleKey ||
            displayContentConfig?.sponsorsTitle ||
            ""
          ).trim(),
        )}
      </Typography>
      <div className="flex flex-row justify-center flex-wrap gap-1">
        {sponsors?.map?.((item, i) => {
          const key = `${item.id}-${i}`

          return (
            <Button
              variant="outlined"
              className="p-1"
              key={key}
              onClick={() => {
                PopupService.pushPopup(<Sponsor sponsor={item} />, "", {
                  maxWidth: "100%",
                  width: "100%",
                  height: "100%",
                })
              }}
            >
              <LazyLoadImage
                alt={`sponsor-${item.id}-${i}`}
                scrollPosition={scrollPosition}
                src={item.iconUrl}
                width={90}
              />
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export const Sponsors = trackWindowScroll<any>(SponsorsList as any)

const $container: any = (hideDiv: boolean, centerTitle: boolean) => {
  const center = centerTitle ? { textAlign: "center" } : {}

  return hideDiv
    ? center
    : {
        borderTopColor: colors.border,
        borderTopWidth: 5,
        paddingTop: 5,
        ...center,
      }
}

export default function Sponsor(props: { sponsor: ISponsor }) {
  const { sponsor } = props
  const { getThisMonthOffers } = useUserStore()
  const [offers, setOffers] = useState<ISponsorOffers[]>([])

  useEffect(() => {
    async function getOffers() {
      const result = await getThisMonthOffers(sponsor.id)
      setOffers(result)
    }
    if (sponsor) {
      getOffers()
    }
  }, [sponsor])

  if (!sponsor) {
    return null
  }

  return (
    <>
      <SponsorOffers offers={offers} />
      <SponsorServices sponsorId={sponsor.id} />
    </>
  )
}

const SponsorOffers = ({ offers }: any) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h5" className="font-bold mb-4">
        {t("Today's Offers")}
      </Typography>
      {!offers?.length ? (
        <Typography variant="h6" className="text-center">
          {t("noOffersAvailable")}
        </Typography>
      ) : (
        offers.map((offer: ISponsorOffers, i: number) => (
          <div
            key={`${offer.title}-${i}`}
            style={{
              borderWidth: 1,
              borderColor: "#DDD",
              borderStyle: "solid",
              marginTop: 10,
              marginBottom: 10,
              padding: 10,
            }}
          >
            <Typography variant="h4">{offer.title}</Typography>
            {/* <Typography variant="h5">{offer.subtitle}</Typography> */}
            <Typography variant="h6">{offer.description}</Typography>
            <Carousel
              cycleNavigation
              fullHeightHover
              indicators
              navButtonsAlwaysVisible
              // height={400}
              autoPlay
              animation="slide"
              // duration={500}
              swipe
              interval={4000}
              stopAutoPlayOnHover
            >
              {offer.images.map((item: SponsorOfferImage, i: number) => (
                <Button
                  variant="text"
                  href={item.link}
                  key={i}
                  className="flex justify-center w-full"
                >
                  <Image
                    src={item.image}
                    style={{
                      height: "50vh",
                      width: "90%",
                      objectFit: "contain",
                    }}
                    alt={"sponsor-image"}
                  />
                </Button>
              ))}
            </Carousel>
          </div>
        ))
      )}
    </>
  )
}
