import { LoadingService } from "../components/LoadingService";
import { PopupService } from "../components/PopupService";

export const load = async (func: any, ss?: any) => {
    try {
        LoadingService.show();
        await func();
        ss && ss();
    } catch (error: any) {
        PopupService.pushPopup(error.message);
    } finally {

        LoadingService.hide();
    }
};