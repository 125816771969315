import { Box, CircularProgress, Container } from '@mui/material';
// src/Profile.tsx
import React, { useState } from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { auth } from '../firebase';
import EditProfile from './profile/EditProfile';
import { useUserStore } from './stores/userStore';
import ViewProfile from './ViewProfile';

const Profile: React.FC = () => {
  const { t } = useTranslation();

  const { isLoggedIn } = useUserStore();
  const [user, loading, error] = useAuthState(auth);

  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isLoggedIn) {
    return <div>{t('No user logged in')}</div>;
  }

  return (
    <Container maxWidth="sm">
      {isEditing ? (
        <EditProfile onSave={handleSave} onCancel={handleCancel} />
      ) : (
        <ViewProfile onEdit={handleEdit} />
      )}
    </Container>
  );
};

export default Profile;
