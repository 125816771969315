import { Outlet, useSearchParams } from "react-router-dom"
import React, { useEffect } from "react"
import { auth, db, getToken, messaging } from "./firebase"
import { doc, updateDoc } from "firebase/firestore"
import { onAuthStateChanged } from "firebase/auth"

import Box from "@mui/material/Box"
import { CopyrightComponent } from "./components/core/CopyrightComponent"
import { handleGetLocation } from "./components/CountryCityStateFetcher"
import { useAuthState } from "react-firebase-hooks/auth"
// import { useColorScheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import { PopupService } from "./react-ui-components/components/PopupService"
import { processUserCode } from "./processUserCode"
import { useSidebar } from "./SidebarProvider"
import SidebarComponent from "./SidebarComponent"
import { Login } from "./components/LoginScreen/Login"

// const mask = new MaskUtils({ mask: "+1 (999) 999-9999", maskChar: "_" })

let qrCodeProcessed = false

interface Props {}

const DRAWER_WIDTH = 240
const TOPBAR_HEIGHT = 130

const App: React.FC<Props> = (props) => {
  const { i18n, t } = useTranslation()
  const { toggle, isExpanded } = useSidebar()
  const [user, loading, error] = useAuthState(auth)

  useEffect(() => {
    i18n.changeLanguage(navigator.language || "en-US")

    handleGetLocation({ local: true })
    // localStorage.setItem(getStorageName(), endTime.toString());
  }, [navigator])

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    // Request permission for notifications
    const requestAndSaveToken = async (uid: string) => {
      try {
        const permission = await Notification.requestPermission()
        if (permission === "granted") {
          const vapidKey = process.env.REACT_APP_WEB_NOTIFICATION_KEY
          const token = await getToken(messaging, {
            vapidKey,
          })
          // console.log("FCM Token:", token)
          if (token && uid) {
            const userDocRef = doc(db, `users/${uid}`)
            await updateDoc(userDocRef, { fcmWebToken: token })
            // console.log("FCM Token saved to Firestore")
          }
        } else {
          console.log("Permission not granted")
        }
      } catch (error) {
        console.error("Error fetching FCM token:", error)
      }
    }

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // If user is authenticated, request and save the FCM token
        requestAndSaveToken(user.uid)
      } else {
        console.log("User not authenticated")
      }
    })
  }, [])

  useEffect(() => {
    if (loading || qrCodeProcessed) {
      return
    }

    const code = searchParams?.get?.("code")

    if (!code) {
      return
    }

    if (user && !user.isAnonymous) {
      qrCodeProcessed = true
      setSearchParams({})

      setTimeout(() => {
        PopupService.setPopup(
          <p className="text-red-800">{t("Please wait while we process your ticket")}</p>,
        )
      }, 0)
      processUserCode(code, t)
    } else {
      setTimeout(() => {
        setSearchParams({})
        PopupService.setPopup(
          <div className="flex text-center flex-col ">
            <p className="text-red-800">
              {t("Please login or register and try again to receive your reward")}
            </p>
            <Login />
          </div>,
        )
      }, 0)
    }
    //  PopupService.setPopup(<Login />)
  }, [user, loading, searchParams, setSearchParams, t])

  return (
    <div className="flex flex-col relative h-screen">
      <div className="overflow-auto h-screen">
        <SidebarComponent drawerWidth={DRAWER_WIDTH}>
          <div>
            <div style={{ height: 55 }}></div>
            <Outlet />
          </div>
        </SidebarComponent>
      </div>
      <Box
        sx={{
          width: { sm: `calc(100vw - ${DRAWER_WIDTH}px)`, xs: "100vw" },
          marginLeft: { sm: `${DRAWER_WIDTH}px`, xs: 0 },
        }}
        aria-label="mailbox folders"
      >
        <CopyrightComponent />
      </Box>
    </div>
  )
}

export default App
