import { Box, CircularProgress } from "@mui/material"

import React, { SyntheticEvent, useState } from "react"

// import { useAutoImage } from "../components/AutoImage"

const errorImage = require("../../assets/images/win-facil-image-error.png")

interface ImageProps {
  style?: React.CSSProperties
  uri?: string
  src?: string
  resizeMode?: "cover" | "contain" | "stretch"
  defaultSource?: string
  maxWidth?: number | string
  maxHeight?: number | string
  tintColor?: string
  children?: React.ReactElement
  onError?: () => void
  indicator?: React.ComponentType
  alt?: string
  width?: string | number
  height?: string | number
  className?: string
  color?: string
}

export const Image: React.FC<ImageProps> = ({
  style,
  uri,
  src,
  alt,
  width,
  height,
  color,
  className,
  resizeMode = "contain",
  defaultSource,
  maxWidth = "100%",
  maxHeight,
  tintColor,
  children,
  onError,
  indicator: Indicator = CircularProgress,
  ...rest
}) => {
  const [loading, setLoading] = useState(true)

  function handleLoad(event: SyntheticEvent<HTMLImageElement, Event>): void {
    setLoading(false)
  }

  src = src || uri

  function handleError(event: SyntheticEvent<HTMLImageElement, Event>): void {
    setLoading(false)
    if (onError) {
      onError()
    } else {
      if (uri && !defaultSource) {
        console.error(`There was an error loading asset. \n(${uri})`)
      }
    }
  }
  // width = width ? (isNaN(Number(width)) ? width : `${Number(width)}px`) : "100%"
  // height = height ? (isNaN(Number(height)) ? height : `${Number(height)}px`) : "100%"

  return (
    <Box className="relative flex items-center justify-center" {...rest}>
      {src ? (
        <img
          color={color}
          className={className}
          src={src}
          alt={alt}
          style={{
            width,
            height,
            ...style,
          }}
          onError={handleError}
          onLoad={handleLoad}
        />
      ) : (
        <img
          color={color}
          className={className}
          src={defaultSource || errorImage}
          alt={alt}
          style={{
            objectFit: "contain",
            width: width || "100%",
            height: height || "100%",
            ...style,
          }}
        />
      )}

      {loading && Indicator && <Indicator />}

      {children}
    </Box>
  )
}
