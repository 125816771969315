import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Close as CloseIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_COUNTRY,
  useLocation,
} from '../react-ui-components/components/location';
import { ILocationItem } from '../react-ui-components/components/models';
import CountryCityStateFetcher from './CountryCityStateFetcher';
import LocationSelector from './LocationSelector';
import { useUserStore } from './stores/userStore';
import { UserLocationButton } from './UserLocationButton';
import { LocationService } from "./LocationService";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '130vw',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
}));

const rootStyle: any = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
};

interface UserLocationProps {
  style?: React.CSSProperties;
  textPosition?: string;
  iconColor?: string;
  iconStyle?: React.CSSProperties;
  hideIcon?: boolean;
  viewOnly?: boolean;
  color?: any;
  fullWidth?: boolean;
  textButton?: boolean;
}

const initialFilter: ILocationItem = { country: '', state: '', city: '' };

export const UserLocation: React.FC<UserLocationProps> = function (props: any) {
  const { viewOnly, textButton, color, style } = props;

  const [modalVisible, setModalVisible] = useState(false);


  if (viewOnly)
    return (
      <UserLocationButton
        hideIcon
        style={style}
        color={color}
        textButton={textButton}
      />
    );

  return (
    <div>
      <UserLocationDialog
        modalVisible={modalVisible}
        style={style}
        setModalVisible={setModalVisible}
      />
      <UserLocationButton
        textButton={textButton}
        onPress={() => setModalVisible(true)}
      />
    </div>
  );
};

export const UserLocationSelector: React.FC<{
  style?: React.CSSProperties;
}> = function ({ style }) {
  const { currentLocation } = useLocation();

  const [selectedCountry, setSelectedCountry] = useState<string>(
    currentLocation?.country || ''
  );
  const [selectedState, setSelectedState] = useState<string>(
    currentLocation?.state || ''
  );
  const [selectedCity, setSelectedCity] = useState<string>(
    currentLocation?.city || ''
  );
  const [userSelectedFilter, setUserSelectedFilter] = useState(initialFilter);
  const [error, setError] = useState<string | null>(null);

  const { availableLocations } = useLocation();
  const { t } = useTranslation();
  const { user: userFromStore } = useUserStore();

  useEffect(() => {
    setSelectedCountry(currentLocation?.country || DEFAULT_COUNTRY.key);
    setSelectedState(currentLocation?.state || '');
    setSelectedCity(currentLocation?.city || '');
  }, [currentLocation]);

  // const validateLocation = (locationObj: ILocationItem) => {
  //   if (!locationObj) {
  //     return false
  //   }
  //   if (!locationObj.country) {
  //     setError(t("Please select a valid country."))
  //     return false
  //   }
  //   if (locationObj.country && !availableLocations[locationObj.country]) {
  //     setError(t("Selected country is not available."))
  //     return false
  //   }
  //   if (locationObj.state && !availableLocations[locationObj.country]?.[locationObj.state]) {
  //     setError(t("Selected province is not available in the chosen country."))
  //     return false
  //   }
  //   if (
  //     locationObj.city &&
  //     !availableLocations[locationObj.country]?.[locationObj.state || ""]?.includes(
  //       locationObj.city,
  //     )
  //   ) {
  //     setError(t("Selected city is not available in the chosen province."))
  //     return false
  //   }
  //   setError(null)
  //   return true
  // }

  // useEffect(() => {
  //   detectCurrentCountry((code: string) => {
  //     if (!selectedCountry && !userFromStore?.userAddress?.country) {
  //       setSelectedCountry(code)
  //     }
  //   })
  //   setUserSelectedFilter({ ...initialFilter, country: "US" })
  // }, [selectedCountry, userFromStore?.userAddress?.country])

  // useEffect(() => {
  //   if (!setFromStore && userFromStore) {
  //     setFromStore = true
  //     const { country, province, city } = userFromStore.userAddress || {}
  //     const locationObj = {
  //       country: country || "US",
  //       state: province || "",
  //       city: city || "",
  //     }
  //     if (validateLocation(locationObj)) {
  //       setSelectedCountry(locationObj.country)
  //       setSelectedState(locationObj.state)
  //       setSelectedCity(locationObj.city)
  //       setUserSelectedFilter(locationObj)
  //     }
  //   }
  // }, [userFromStore])

  // useMemo(() => {
  //   if (userSelectedFilter) {
  //     setSelectedCountry(userSelectedFilter.country || location?.country || "")
  //     setSelectedState(userSelectedFilter.state || location?.state || "")
  //     setSelectedCity(userSelectedFilter.city || location?.city || "")
  //   }
  // }, [userSelectedFilter])

  // useEffect(() => {
  //   const locationObj = {
  //     country: selectedCountry,
  //     state: selectedState,
  //     city: selectedCity,
  //   }
  //   if (validateLocation(locationObj)) {
  //     LocationService.setLocation(locationObj)
  //     setUserSelectedFilter(locationObj)
  //   }
  // }, [selectedCountry, selectedState, selectedCity])

  // useEffect(() => {
  //   if (validateLocation(location)) {
  //     setUserSelectedFilter(location)
  //   }
  // }, [location])

  const handleChange = (name: string, value: string) => {

    
    // let locationObj = { ...userSelectedFilter }
    switch (name) {
      case "country":
        // setSelectedCountry(value)
        // locationObj = { ...locationObj, country: value, state: "", city: "" }
        LocationService.setLocation({
          country: value,
          state: '',
          city: '',
        })
        break
      case "state":
        // setSelectedState(value)
        // locationObj = { ...locationObj, state: value, city: "" }
        LocationService.setLocation({
          country: currentLocation.country,
          state: value,
          city: '',
        })
        break
      case "city":
        // setSelectedCity(value)
        // locationObj = { ...locationObj, city: value }
        LocationService.setLocation({
          country: currentLocation.country,
          state: currentLocation.state,
          city: value,
        })
        break
      default:
        return
    }
    // if (validateLocation(locationObj)) {
    //   setUserSelectedFilter(locationObj)
    // }
  };

  return (
    <div style={style}>
      {error && <Alert severity="error">{error}</Alert>}

      <div className="flex flex-row w-full justify-end">
        <CountryCityStateFetcher label={t('Current Location')} />
      </div>
      <LocationSelector
        userSelectedCountry={selectedCountry}
        userSelectedState={selectedState}
        userSelectedCity={selectedCity}
        onChange={handleChange}
      />
    </div>
  );
};

export const UserLocationDialog: React.FC<{
  modalVisible?: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  style?: React.CSSProperties;
}> = function ({ style, modalVisible = false, setModalVisible }) {
  const { t } = useTranslation();

  return (
    <div style={style}>
      <BootstrapDialog open={modalVisible}>
        <div style={rootStyle}>
          <DialogTitle sx={{ m: 0, p: 2 }}>{t`Select Location`}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setModalVisible(false)}
            sx={{ position: 'absolute', right: 8, top: 8, color: 'grey.500' }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent
          dividers
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <CountryCityStateFetcher label={t('Current Location')} />
          <UserLocationSelector />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
