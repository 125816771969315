import HomeIcon from "@mui/icons-material/Home"
import { IconButton } from "@mui/material"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"

export const HomeLink: React.FC<{
  iconOnly?: boolean
}> = ({ iconOnly }) => {
  const { t } = useTranslation()
const navigate = useNavigate();

  return (
    <>
      {iconOnly ? (
        <IconButton onClick={()=>navigate('/#__t')}>
          <HomeIcon color="secondary" />
        </IconButton>
      ) : (
        <Link to="/#__t">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={t("Home")} />
            </ListItemButton>
          </ListItem>
        </Link>
      )}
    </>
  )
}
