import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { and, collection, getDocs, query, where } from "firebase/firestore"
import React, { useEffect, useState } from "react"
import { auth, db } from "../firebase"

import { DateTime } from "luxon"
import { LoadingService } from "../react-ui-components/components/LoadingService"

import { PopupService } from "../react-ui-components/components/PopupService"

import { useAuthState } from "react-firebase-hooks/auth"
import { useTranslation } from "react-i18next"
import { colors } from "../utils/colors"
import TransferCodeCard from "./TransferCodeCard"
import { useUserStore } from "./stores/userStore"
import { userTicketsQuery } from "./stores/UserContext"
import { uniqueArray } from "../utils"
import htmr from "htmr"

export interface Ticket {
  qrCodeDataUrl?: string //base 64 qr code
  code: string
  createdBy: string
  createdDate: number
  design: Design
  disabled: boolean
  expirationDate: number
  id: string
  index: number
  isSold: boolean
  isWinner: boolean
  order: number
  payerUID: string
  points: number
  raffleId: string
  raffleTicketId: string
  raffleType: string
  ticketSellerUID: string
  value: number
}

export interface Design {
  backgroundColor: string
  color: string
  height: number
  image: string
  justifyContent: string
  margin: number
  padding: number
  textColor: string
  width: number
}

// const TestTicket = {
//   code: "123456",
//   createdBy: "uid",
//   createdDate: 1719621689470,
//   design: {
//     backgroundColor: "#FDB000",
//     color: "#000",
//     height: 120,
//     image: "",
//     justifyContent: "center",
//     margin: 5,
//     padding: 5,
//     textColor: "color",
//     width: 180,
//   },
//   disabled: false,
//   expirationDate: 1727717443000,
//   id: "uid",
//   index: 0,
//   isSold: false,
//   isWinner: false,
//   order: 0,
//   payerUID: "uid",
//   points: 0,
//   raffleId: "id",
//   raffleTicketId: "id",
//   raffleType: "str",
//   ticketSellerUID: "sellerUID",
//   value: 0,
// }

const Tickets: React.FC = () => {
  const { usertickets } = useUserStore()

  const { t } = useTranslation()
  const [tickets, setTickets] = useState<Ticket[]>(usertickets || [])
  const [raffles, setRaffles] = useState<any>([])
  const [raffleIds, setRaffleIds] = useState<any>([])
  const [selectedRaffleId, setSelectedRaffleId] = useState<any>("")
  const [user] = useAuthState(auth)

  useEffect(() => {
    setTickets(usertickets)

    const unique = uniqueArray(usertickets.map((x) => x.raffleId))
    setRaffleIds(unique)
  }, [usertickets])

  useEffect(() => {
    ;(async () => {
      if (!user || !selectedRaffleId) {
        return
      }
      try {
        LoadingService.setLoading(true)
        const queryResult = userTicketsQuery(user.uid, selectedRaffleId)
        const ticketSnapshot = await getDocs(queryResult)
        const ticketList: Ticket[] = ticketSnapshot.docs.map((doc) => {
          const data = doc.data() as Ticket
          return data
        })

        setTickets(ticketList)
      } catch (error) {
      } finally {
        LoadingService.setLoading(false)
      }
    })()
  }, [selectedRaffleId])

  useEffect(() => {
    if (raffleIds?.length) {
      const fetchRaffles = async () => {
        if (!user) {
          return
        }

        try {
          LoadingService.setLoading(true)
          const col = collection(db, `raffles`)
          const snap = await getDocs(query(col, where("id", "in", raffleIds)))
          const list: any[] = snap.docs.map((doc) => {
            const data = doc.data() as any
            return data
          })

          setRaffles(list)
        } catch (error) {
        } finally {
          LoadingService.setLoading(false)
        }
      }

      fetchRaffles()
    }
  }, [raffleIds, user])

  // const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFilterText(e.target.value)
  // }

  // const handleClearFilter = () => {
  //   setFilterText("")
  // }

  if (!tickets?.length) {
    return <Text tx="No tickets available" size="lg" className="text-center" />
  }

  // const filteredTickets = tickets.filter(
  //   (ticket) => ticket.code.includes(filterText) || ticket.createdBy.includes(filterText),
  // )

  return (
    <div>
      <div className="flex mb-5">
        {/* raffles: {JSON.stringify(raffles)} */}
        <FormControl fullWidth>
          <InputLabel>{t("Select Raffle")}</InputLabel>
          <Select
            className="flex flex-column gap-10"
            value={selectedRaffleId}
            label={t("Select Raffle")}
            onChange={(val) => {
              const raffleId: any = val?.target?.value || ""
              setSelectedRaffleId(raffleId)
            }}
          >
            <MenuItem value="">
              <em>{t("All")}</em>
            </MenuItem>
            {raffles.map((raffle: any, i: number) => (
              <MenuItem
                key={`${raffle}-${i}`}
                value={raffle.id}
                style={{
                  textTransform: "uppercase",
                }}
                className="flex flex-row"
              >
                {htmr(raffle.title)}
                {raffle.subtitle ? <>-{htmr(raffle.subtitle)} </> : null}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div
        className="flex flex-row flex-wrap gap-1  align-center justify-center"
        style={{ zoom: 0.8 }}
      >
        {tickets?.map((item, i) => {
          return (
            <TicketItem
              key={`${item.id}-${i}`}
              onUpdateQRCode={(qrCode: any) => {
                item.qrCodeDataUrl = qrCode
                setTickets(
                  tickets.map((ticket) => {
                    if (ticket.id === item.id) {
                      ticket.qrCodeDataUrl = qrCode
                    }
                    return ticket
                  }),
                )
              }}
              item={item}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Tickets

function View({ children, style, className }: any) {
  return (
    <div style={style} className={`flex flex-col ${className || ""}`}>
      {children}
    </div>
  )
}

function Row({
  children,
  tx,
  text,
  labelTx,
  labelText,
  value,
  textColor,
  valueSize,
  style,
  size,
}: any) {
  const { t } = useTranslation()
  return (
    <div
      className="flex flex-row items-baseline"
      style={{
        ...style,
        color: textColor,
      }}
    >
      <Typography variant="subtitle1" className={`font-bold text-${size}`}>
        {t(labelTx || labelText)}
        {": "}
        {children}
      </Typography>

      <Typography variant="subtitle2" className={`text-${valueSize}`}>
        {typeof value === "string" ? t(value || tx || text) : value}
      </Typography>

      {children}
    </div>
  )
}

/**
 * Hides content based on a property from an object.
 * @param {HTMLElement} element - The HTML element to hide.
 * @param {Object} item - The object containing the property to check.
 * @param {string|Function} property - The property name or a function that returns a boolean.
 * @returns {HTMLElement|null} - The hidden element or null if not hidden.
 */
export const hide = (element: any, item: any, property: Function | string) => {
  if (!element || typeof element !== "object" || !item || typeof item !== "object") {
    console.error("Invalid arguments. Please provide valid HTML element and object.")
    return null
  }

  if (typeof property === "string") {
    if (!Object.hasOwnProperty.call(item, property)) {
      console.error(`Object does not have the property: ${property}`)
      return null
    }

    return item[property] ? element : null
  } else if (typeof property === "function") {
    return property(item) ? element : null
  } else {
    console.error("Invalid property parameter. Please provide a valid property name or function.")
    return null
  }
}

export const getLuxonDate = (date: any, isSeconds = false): any => {
  if (!date) {
    return console.log(`Invalid date (${date}).`)
  }
  let date_ = null
  if (isSeconds) {
    date_ = DateTime.fromMillis(date)
    date_ = date_.isValid ? date_ : DateTime.fromSeconds(date)
  } else {
    date_ = DateTime.fromISO(date)
    date_ = date_.isValid ? date_ : DateTime.fromJSDate(date)
  }
  return date_
}

function Text({ children, tx, text, size = "sm", preset = "bold", className }: any) {
  const { t } = useTranslation()
  return (
    <Typography className={`text-${size} font-${preset} ${className || ""}`}>
      {t(tx || text)}
      {children}
    </Typography>
  )
}
//***************************************************************************************************** */

export const GetTicketDesign = (obj: any, offset?: any, marginAndPadding?: boolean): any => {
  const mAndA = marginAndPadding
    ? {}
    : {
        margin: +(obj?.margin || DefaultTicketDesign.margin) + (offset?.margin || 0),
        padding: +(obj?.padding || DefaultTicketDesign.padding) + (offset?.padding || 0),
      }
  return {
    width: +(obj?.width || DefaultTicketDesign.width) + (offset?.width || 0),
    height: +(obj?.height || DefaultTicketDesign.height) + (offset?.height || 0),
    ...mAndA,
  }
}

export const DefaultTicketDesign: any = {
  width: 180,
  height: 120,
  margin: 5,
  padding: 5,
}

export function TicketItem(props: any) {
  const { item, onUpdateQRCode } = props
  // console.log('TicketItem------>',item)
  const [design, setDesign] = useState({
    ...item?.design,
    ...GetTicketDesign(item?.design),
  })
  const [textColor, setTextColor] = useState(item?.design?.textColor)
  useEffect(() => {
    // console.log("item.design----->", design)
    setDesign({ ...item?.design, ...GetTicketDesign(item?.design) })
    setTextColor(item?.design?.textColor)
  }, [item?.design, item?.design?.backgroundImage])

  if (item?.__empty) {
    return (
      <View style={$emptyDesign(design)}>
        <></>
      </View>
    )
  }

  return (
    <div
      // fullWidth
      // sx={{ mt: 2 }}
      style={{
        backgroundColor: design.backgroundColor,
        // width: +design?.width,
        // height: +design?.height,
        margin: +design.margin,
        padding: +design.padding || 5,
        cursor: "pointer",
        // borderRadius: 10,
        border: "black solid 11",
      }}
      onClick={() => {
        PopupService.pushPopup(<TransferCodeCard ticket={item} onUpdateQRCode={onUpdateQRCode} />)
      }}
      className="flex items-center"
    >
      <View className="overflow-hidden">
        <View
          style={{
            backgroundColor: design.color || "#FFF",
            width: +design?.width - +design.margin * 2,
            height: +design?.height - +design.margin * 2,
            margin: +design.margin,

            justifyContent: design.justifyContent,
          }}
          className="overflow-hidden p-2"
        >
          <Row
            hide={!item.code}
            textColor={textColor}
            labelTx="code"
            value={item.code}
            style={$row}
          />

          {!item.expirationDate ? null : (
            <Row
              textColor={textColor}
              labelTx="date"
              value={getLuxonDate(+item.expirationDate, true).toFormat("D")}
              style={$row}
            />
          )}

          {!item.raffleType ? (
            <Text text="TICKET" size="xxl" style={$empty(textColor)} />
          ) : (
            <Row
              textColor={textColor}
              numberOfLines={2}
              labelTx="raffle"
              value={htmr(item.raffleType)}
              style={$row}
              valueSize="xxs"
            />
          )}

          {hide(
            <View style={$win}>
              <Text tx="win" preset="bold" size="md" style={$text} />
            </View>,
            item,
            "isWinner",
          )}
        </View>
      </View>
    </div>
  )
}
const $win: any = {
  position: "absolute",
  right: 0,
  bottom: 0,
  backgroundColor: colors.error,
  borderRadius: 10,
  // height: 30,
  padding: 2,
  justifyContent: "flex-start",
}

const $row: any = {
  alignSelf: "flex-start",
}

const $text: any = {
  color: "#36ab53",
  textAlign: "center",
}

function $emptyDesign(design: any): any {
  return {
    margin: design?.margin,
    width: +design?.width,
    height: +design?.height,
  }
}

function $empty(textColor: string): any {
  return { alignSelf: "center", color: textColor }
}
