import { useEffect, useState } from "react";

import { VerticalAlignTop } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AdsList } from "./Ads/AdsList";
import Raffles from "./Raffles/Raffles";
import { Sponsors } from "./Sponsors";

const goToTopStyle = {
  position: "fixed",
  bottom: 9,
  right: 21,
};
const Home = () => {
  const { t } = useTranslation();
  const [showGoTop, setShowGoTop] = useState(false);
  const handleVisibleButton = () => {
    const position = window.scrollY;

    if (position > 50) {
      return setShowGoTop(true);
    } else if (position < 50) {
      return setShowGoTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleVisibleButton);
  }, []);

  return (
    <div>

      <AdsList />
      <Raffles />
      <Sponsors />

      {/* <Typography style={{ marginBlock: 10 }}>{t("home.info")}</Typography> */}
      {/* <Typography style={{ marginBlock: 10 }}>
        WinFacil allows you to participate in various types of giveaways with our easy ticketing system.

        Simply click the “Interested” button and contact your local promoter and get your ticket. That’s it!

        At WinFacil, you can also accumulate Easy Coins, our virtual currency that allows you to trade for raffles. You can also transfer easy coins to friends and family.

        Enjoy all the content that WinFácil has to offer, such as exclusive offers and specials from our fantastic sponsors.

        WinFácil, the App of great surprises!
      </Typography> */}
      {/* <div style={{ justifyContent: 'center', display: 'flex' }}>
      
      </div> */}
      {!showGoTop ? null : (
        <Link to="#__t" variant="outlined" size="small" style={goToTopStyle}>
          <VerticalAlignTop />
        </Link>
      )}
    </div>
  );
};

export default Home;
