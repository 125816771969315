import React, { useEffect, useState } from "react"

import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"

import { LocationOn } from "@mui/icons-material"
import { IconButton, CircularProgress } from "@mui/material"
import { LocationService } from "./LocationService"

export const CountryCityStateFetcher = ({ label }: any) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{
    country: string
    city: string
    state: string
  } | null>(null)
  const { t } = useTranslation()
  const [showButton, setShowButton] = useState(true)

  useEffect(() => {
    try {
      setShowButton(!!navigator.geolocation)
      if (!navigator.geolocation) {
        const userRegion = (Intl as any).Locale ? new Intl.Locale(navigator.language).region : null
        if (userRegion) {
          setData({ country: userRegion, city: "", state: "" })
          setShowButton(true)
        }
      } else {
        navigator.geolocation.getCurrentPosition(
          () => {
            setShowButton(true)
          },
          () => {
            setShowButton(false)
          },
        )
      }
    } catch (error: any) {
      setShowButton(false)
      console.log("geolocation error:", error)
    }
  }, [navigator.geolocation])

  if (!showButton) {
    return null
  }

  return (
    <>
      <IconButton
        size="small"
        color="secondary"
        onClick={() => {
          handleGetLocation({ setLoading, setData })
        }}
        className="flex flex-row gap-1"
      >
        {loading ? <CircularProgress size={20} color="inherit" /> : <LocationOn />}
        {!label ? null : t(label)}
      </IconButton>
    </>
  )
}

export const MyLocationIcon: React.FC<{
  label?: string
  loading?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}> = ({ label, loading, onClick }) => {
  const { t } = useTranslation()

  return (
    <>
      <IconButton size="small" color="secondary" onClick={onClick} className="flex flex-row gap-1">
        {loading ? <CircularProgress size={20} color="inherit" /> : <LocationOn />}
        {!label ? null : t(label)}
      </IconButton>
    </>
  )
}
/*
if (!showButton) {
    return PopupService.pushPopup(<UserLocationSelector />)
  }
*/
export const handleGetLocation = (props?: { setData?: any; setLoading?: any; local?: boolean }) => {
  if (props?.local) {
    const localLocStr = localStorage.getItem("WINLOC") || ""
    const savedLOC = JSON.parse(localLocStr)

    if (savedLOC) {
      LocationService.setLocation(savedLOC)
      return
    }
  }

  props?.setLoading?.(true)
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords
        fetchLocationData(latitude, longitude, props?.setData)
        props?.setLoading?.(false)
      },
      (error) => {
        console.error("Error getting geolocation:", error)
        props?.setLoading?.(false)
      },
    )
  } else {
    console.error("Geolocation is not supported by this browser.")
    props?.setLoading?.(false)
  }
}

const fetchLocationData = async (latitude: number, longitude: number, setData: any) => {
  try {
    const apiKey = process.env.REACT_APP_GOOGLE_GEO_API_KEY
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`,
    )

    const responseData = await response.json()

    if (responseData.status === "OK") {
      const addressComponents = responseData.results[0].address_components
      let country = ""
      let state = ""
      let city = ""

      addressComponents.forEach((component: any) => {
        const types = component.types
        if (types.includes("country")) {
          country = component.short_name
        } else if (types.includes("administrative_area_level_1")) {
          state = component.long_name
        } else if (types.includes("locality")) {
          city = component.long_name
        }
      })

      setData && setData({ country, city, state })

      LocationService.setLocation({
        country: country,
        state: state,
        city: city,
      })

      // Optionally, save the data to Firestore
      // await addDoc(collection(db, 'locations'), { country, city, state });
    } else {
      console.error("Failed to retrieve location data:", responseData.status)
    }
  } catch (error) {
    console.error("Error fetching location data:", error)
  }
}

export const getCurrentLocation: {} = () => {}

export default CountryCityStateFetcher
