import {
  doc,
  onSnapshot,
  and,
  where,
  or,
  query,
  collection,
  getDoc,
  updateDoc,
  setDoc,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { LoadingService } from "../../react-ui-components/components/LoadingService";
import { auth, db } from "../../firebase";
import { ILocationItem } from "../../react-ui-components/components/models/ILocation";
import { PopupService } from "../../react-ui-components/components/PopupService";
import Raffles from "../../react-ui-components/components/Raffles";
import { useLocation } from "../../react-ui-components/components/location";
import { Login } from "../LoginScreen/Login";

const listenForRaffleConfigChanges = function (setData: any) {
  LoadingService.setLoading(true);
  const querySnapshot = doc(db, 'app-configurations/display-content-config');

  return onSnapshot(querySnapshot, {
    next: (data) => {
      setData(data.data());
      LoadingService.setLoading(false);
    },
    error: (data) => {
      LoadingService.setLoading(true, data?.message || data, true);
    },
    complete: () => {
      LoadingService.setLoading(false);
    },
  });
};

const listenForRafflesDataChanges = function (
  setData: any,
  location: ILocationItem
) {
  LoadingService.setLoading(true);
  // console.log("listenForRafflesDataChanges---location--->", location)
  let arr: any[] = [
    and(
      where('location.country', '==', location?.country || 'US'),
      where('raffleDate', '>=', Date.now())
    ),
  ];

  if (location?.country) {
    arr = [
      and(
        or(
          where('location.country', '==', '*'),
          where('location.country', '==', ''),
          where('location.country', '==', location.country || 'US')
        ),

        where('raffleDate', '>=', Date.now())
      ),
    ];
  } else if (location?.state) {
    arr = [
      and(
        or(
          where('location.country', '==', '*'),
          where('location.country', '==', ''),
          where('location.province', '==', '*'),
          where('location.province', '==', '')
        ),
        where('location.country', '==', location.country || 'US'),
        where('location.province', '==', location.state),
        where('raffleDate', '>=', Date.now())
      ),
    ];
  } else if (location?.city) {
    arr = [
      and(
        or(
          where('location.country', '==', '*'),
          where('location.country', '==', ''),
          where('location.province', '==', '*'),
          where('location.province', '==', ''),
          where('location.city', '==', ''),
          where('location.city', '==', '*')
        ),
        where('location.country', '==', location.country || 'US'),
        where('location.province', '==', location.state),
        where('location.city', '==', location.city),
        where('raffleDate', '>=', Date.now())
      ),
    ];
  }

  const querySnapshot = query(collection(db, 'raffles'), ...arr);

  return onSnapshot(querySnapshot, {
    next: (snap) => {
      const arr: any[] = [];
      snap.forEach((data) => arr.push(data.data()));
      setData(arr);
      LoadingService.setLoading(false);
    },
    error: (data) => {
      PopupService.pushPopup(data?.message || 'Error loading data.', 'Error!');
      LoadingService.setLoading(false);
    },
    complete: () => {
      LoadingService.setLoading(false);
    },
  });
};

export default function RafflesComponent() {

  const [user] = useAuthState(auth);
  const [raffles, setRaffles] = useState<any[]>([]);
  const { currentLocation: location } = useLocation();

  const [rafflesConfig, setRafflesConfig] = useState({
    descriptionLength: 50,
    hideAllActions: true,
    imageResizeMode: 'contain',
    rafflesImageHeight: 500,
  });

  
  useEffect(() => {
    return listenForRaffleConfigChanges(setRafflesConfig);
  }, [location, user]);

  useEffect(() => {
    return listenForRafflesDataChanges(setRaffles, location);
  }, [location, user]);

  const handleLike = async (raffleId: string): Promise<any> => {
    if (!user || user.isAnonymous) {
      return PopupService.setPopup(<Login />);
    }

    const likeRef = doc(db, `raffles/${raffleId}/likes`, user.uid);
    const likeDoc = await getDoc(likeRef);

    if (likeDoc.exists()) {
      await updateDoc(likeRef, { liked: !likeDoc.data().liked });
    } else {
      await setDoc(likeRef, { liked: true });
    }

    setRaffles(
      raffles.map((raffle: any) =>
        raffle.id === raffleId ? { ...raffle, liked: !raffle.liked } : raffle
      )
    );
  };

  return (
    <Raffles
      raffles={raffles}
      user={user}
      rafflesConfig={rafflesConfig}
      handleLike={handleLike}
      getLikesRef={(raffleId: string) =>
        collection(db, `raffles/${raffleId}/likes`)
      }
      fetchLikes={null}
      termsIconProps={{
        src: require('../../assets/icons/termsIcon.png'),
        height: 32,
      }}
    />
  );
}
