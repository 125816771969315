import { Button, Icon } from '@mui/material';
import { colors, typography } from '../theme';

import { toJS } from 'mobx';
import { formatCash } from '../utils';
// import { useNavigation } from "@react-navigation/native"
import { useUserStore } from './stores/userStore';

export interface UsersCounterProps {
  style?: any;
  className?: string;
  size?: string;
  spaceBetween?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  left?: boolean;
}

export const UsersCounter = function UsersCounter(props: UsersCounterProps) {
  const {
    style,
    className,
    size = 'medium',
    spaceBetween,
    variant = 'contained',
    left = true,
  } = props;
  const $styles = { ...$container, ...style, spaceBetween: spaceBetween };
  const { usersCount, usersCountIcon } = useUserStore();
  const iconDefault: any = {
    name: 'people_outline',
    isFont: true,
    isFa5: true,
    style: { marginLeft: 1 },
    size,
    isUrl: false,
    ...toJS(usersCountIcon),
  };

  return (
    <Button
      variant={variant}
      style={$styles}
      className={`flex flex-row gap-1 ${className}`}
      startIcon={
        left ? (
          <Icon fontSize={iconDefault.size} style={toJS(iconDefault.style)}>
            {iconDefault.name}
          </Icon>
        ) : null
      }
      endIcon={
        left ? null : (
          <Icon fontSize={iconDefault.size} style={toJS(iconDefault.style)}>
            {iconDefault.name}
          </Icon>
        )
      }
      onClick={() => {
        // PopupService.setPopup(<LoginPage />)
        // if (isAuthenticated) {
        //   navigation.navigate("Popup", { screen: "UserProfileScreen" })
        // } else {
        //   navigation.navigate("Login")
        // }
      }}
    >
      {formatCash(usersCount)}
    </Button>
  );
};

const $container: any = {
  justifyContent: 'center',
  flexDirection: 'row',
};

const $text: any = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
};
