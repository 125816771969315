import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EmptyComponent } from './EmptyComponent';
import { IRaffle } from './models/IRaffle';
import { IRaffleConfig } from './models/IRaffleConfig';
import { RaffleItem } from './RaffleItem';

/* The `Raffles` component in the provided code snippet is a functional component in React that takes
several props and renders a list of raffles with associated information. Here's a breakdown of what
the component does: */

const Raffles = ({
  handleLike,
  user,
  getLikesRef,
  raffles: rafflesList,
  rafflesConfig = {
    descriptionLength: 50,
    hideAllActions: true,
    imageResizeMode: 'contain',
    rafflesImageHeight: 500,
  },
  termsIconProps,
}: {
  raffles: IRaffle[];
  fetchLikes: any;
  user: any;
  handleLike: (raffleId: string) => Promise<any>;
  getLikesRef: any;
  rafflesConfig: IRaffleConfig;
  termsIconProps: any;
}) => {
  const { t } = useTranslation();
  const [raffles, setRaffles] = useState<IRaffle[]>([]);

  useEffect(() => {
    setRaffles(rafflesList?.filter?.((x) => !x.disabled) || []);
  }, [rafflesList]);

  if (!raffles?.length) {
    return <EmptyComponent message={t('No Raffles Available At The Moment')} className="my-10" />;
  }

  return (
    <div
      style={{
        backgroundColor: rafflesConfig.backgroundColor || undefined,
      }}
    >
      {raffles.map((raffle: any, i: number) => (
        <RaffleItem
          key={`${raffle.name}-${i}`}
          raffle={raffle}
          user={user}
          getLikesRef={getLikesRef}
          handleLike={handleLike}
          rafflesConfig={rafflesConfig}
          termsIconProps={termsIconProps}
        />
      ))}
    </div>
  );
};

// function Like(props: {
//   handleLike: (raffleId: string) => Promise<void>;
//   raffle: any;
//   fetchLikes: Function;
//   liked: any;
//   likeCount: number;
//   loading: boolean;
// }) {
//   const { handleLike, raffle, liked, likeCount, loading } = props;

//   return (
//     <Stack direction='row' spacing={0.1} alignItems='center'>
//       {/* <Typography variant="caption">{t("like")}</Typography> */}

//       <IconButton onClick={loading ? undefined : () => handleLike(raffle.id)}>
//         {loading ? (
//           <CircularProgress size={24} />
//         ) : liked ? (
//           <Favorite color='error' />
//         ) : (
//           <FavoriteBorder />
//         )}
//       </IconButton>

//       <Typography variant='body2'>{formatCash(likeCount)}</Typography>
//     </Stack>
//   );
// }

export default Raffles;
