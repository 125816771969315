import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EmptyDataProps {
  message?: string; // message or i18n key for the message
  subMessage?: string; // subMessage or i18n key for the subMessage
  className?: string;
}

export const EmptyComponent: React.FC<EmptyDataProps> = ({
  message,
  subMessage,
  className,
}) => {
  const { t } = useTranslation();

  return !message ? null : (
    <Box
      className={`flex flex-col items-center justify-center w-full h-full ${className || ''}`}
      sx={{
        backgroundColor: 'background.default',
        padding: 2,
        borderRadius: 1,
        border: '1px dashed',
        borderColor: 'divider',
      }}
    >
      <Typography variant="h6" color="textSecondary" className="mb-2">
        {t(message)}
      </Typography>
      {!subMessage ? null : (
        <Typography variant="body2" color="textSecondary">
          {t(subMessage)}
        </Typography>
      )}
    </Box>
  );
};
