import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import {
  addDoc,
  and,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { auth, db } from '../firebase';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { useLocation } from '../react-ui-components/components/location/LocationSelector';
import { getLocationQuery } from '../utils';
import ServiceForm from './ServiceForm';
import { Image } from "./Tickets/Image";


function SponsorServices({ sponsorId }: any) {
  const { t } = useTranslation();
  const [services, setServices] = useState<any>([]);
  const [selectedService, setSelectedService] = useState<any>(null);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [canAddService, setCanAddService] = useState<boolean>(false);
  const [user] = useAuthState(auth);

  const { currentLocation: location } = useLocation();

  useEffect(() => {
    if (!user) {
      return;
    }

    const fetchSponsor = async () => {
      const q = await getDoc(doc(db, 'sponsors', sponsorId));
      const data: any = q.data();

      setCanAddService(!!data.userIds?.find((x: string) => x === user.uid));
      return data;
    };

    fetchSponsor();

    const fetchServices = async () => {
      const arr: any[] = getLocationQuery(location);
      const q = arr.length
        ? query(collection(db, `sponsors/${sponsorId}/services`), and(...arr))
        : collection(db, `sponsors/${sponsorId}/services`);
      const querySnapshot = await getDocs(q);
      const servicesData: any[] = [];
      querySnapshot.forEach((doc) => {
        servicesData.push({ id: doc.id, ...doc.data() });
      });
      setServices(servicesData);
    };

    fetchServices();
  }, [location.city, location.country, location.state, sponsorId, user]);

  const handleAddService = () => {
    setSelectedService(null);
    setOpenForm(true);
  };

  const handleEditService = (service: any) => {
    setSelectedService(service);
    setOpenForm(true);
  };

  const handleDeleteService = async (serviceId: string) => {
    await deleteDoc(doc(db, `sponsors/${sponsorId}/services`, serviceId));
    setServices(services.filter((service: any) => service.id !== serviceId));
  };

  //   const handleUpload = async (id: any, fieldName: any, image: RequestInfo | URL, imageInfo: any, imagePropName: any) => {
  //     const isBase64 = isBase64Image(image)
  //     if (!isBase64) {
  //       return imageInfo
  //         ? {
  //             ...imageInfo,
  //             image,
  //           }
  //         : image
  //     }

  //     const uid = generateUUID()
  //     const storageRef = ref(
  //       storage,
  //       `${Service.IMAGES_BUCKET}/${id}/${fieldName}-${uid}-${Date.now()}.${getFileExtension(image)}`,
  //     )

  //     const response = await fetch(image)
  //     const blob = await response.blob()
  //     await uploadBytes(storageRef, blob)

  //     const downloadURL = await getDownloadURL(storageRef)
  //     return imageInfo
  //       ? {
  //           ...imageInfo,
  //           image: downloadURL,
  //         }
  //       : downloadURL
  //   }

  const handleSubmit = async (serviceData: {
    image: string;
    description: string;
    title: string;
    linkUrl: string;
  }) => {
    if (!user) {
      return;
    }

    serviceData.image = '';
    if (selectedService) {
      const serviceRef = doc(
        db,
        `sponsors/${sponsorId}/services`,
        selectedService.id
      );
      await updateDoc(serviceRef, serviceData);
    } else {
      await addDoc(collection(db, `sponsors/${sponsorId}/services`), {
        ...serviceData,
        userIds: [user.uid],
      });
    }
    setOpenForm(false);
    setSelectedService(null);
  };

  if (!services?.length) {
    return null;
  }

  return (
    <div className="mr-10">
      <Typography variant="h5" className="font-bold mb-4">
        {t('Products & Services')}
      </Typography>
      {canAddService ? (
        <Button variant="contained" color="primary" onClick={handleAddService}>
          {t('addService')}
        </Button>
      ) : null}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {services.map((service: any) => (
          <Card key={service.id}>
            <a href={service.linkUrl} target="_blank" rel="noopener noreferrer">
              <CardContent>
                <Typography variant="h5" color="primary">
                  {service.title}
                </Typography>
                <Typography>{service.description}</Typography>
                <Image
                  src={service.image}
                  alt={service.title}
                  className="w-full h-auto mt-2"
                />

                <div className="flex flex-row gap-1">
                  <Typography color="primary">{`${service.name}`}</Typography>
                  {!service.value ? null : (
                    <Typography color="info">{`${service.value.toFixed(2)} ${
                      service.currency?.substring(0, 3) || ''
                    }`}</Typography>
                  )}
                </div>
              </CardContent>
            </a>
            {!!canAddService && (
              <CardActions>
                <Button size="small" onClick={() => handleEditService(service)}>
                  {t('editService')}
                </Button>
                <Button
                  size="small"
                  onClick={() => handleDeleteService(service.id)}
                >
                  {t('deleteService')}
                </Button>
              </CardActions>
            )}
          </Card>
        ))}
      </div>
      <ServiceForm
        open={openForm}
        onClose={() => setOpenForm(false)}
        onSubmit={handleSubmit}
        service={selectedService}
      />
    </div>
  );
}

export default SponsorServices;
