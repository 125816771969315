import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ILocationContext } from '../models/ILocation';
import { getCountryInfo } from '../utils';
import { useLocation } from './LocationSelector';

export const CountryLocationSelector = ({
  value,
  onChange,
  label,
  context,
}: any) => {
  const { t } = useTranslation();
  const {
    currentLocation,
    availableLocations,
    setCurrentLocation,
    loading,
  }: ILocationContext = useLocation(context);

  const [countries, setCountries] = useState<any[]>([]);

  useEffect(() => {
    if (availableLocations) {
      const arr = Object.keys(availableLocations);
      setCountries(arr);
    }
  }, [availableLocations]);
  function updateLocation(country: string) {
    const loc = {
      country: country || '',
      city: '',
      state: '',
      states: !availableLocations
        ? []
        : !country
          ? []
          : Object.keys(availableLocations?.[country] || {}),
      cities: [],
    };
    setCurrentLocation && setCurrentLocation(loc);
  }

  useEffect(() => {
    updateLocation(value);
  }, [value]);

  const handleCountryChange = (
    event: { target: { value: any } },
    child: any
  ) => {
    const value = event.target.value;
    updateLocation(value);

    onChange && onChange('country', value);
  };

  return (
    <div className="p-4">
      {loading ? (
        <CircularProgress />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <FormControl fullWidth className="mb-4">
            <InputLabel>{t(label || 'Select Country')}</InputLabel>
            <Select
              value={currentLocation.country || ''}
              onChange={handleCountryChange}
              displayEmpty
              renderValue={(value) =>
                value ? (
                  <div className="flex items-center gap-1">
                    <div>{getCountryInfo(value)?.emoji || ''}</div>
                    <div>{t('' + value || 'Any')}</div>
                  </div>
                ) : (
                  <span>{t('Select Country')}</span>
                )
              }
            >
              {!countries
                ? null
                : countries.map((country: string) => (
                    <MenuItem key={country} value={country}>
                      <div className="flex items-center gap-1">
                        <span> {getCountryInfo(country)?.emoji || ''}</span>
                        <span> {t(country || 'Any')}</span>
                      </div>
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </motion.div>
      )}
    </div>
  );
};
