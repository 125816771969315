import { TFunction } from "i18next";
import { SupportInfo } from "./components/SupportInfo";
import { PopupService } from "./react-ui-components/components/PopupService";
import { functions } from "./firebase";
import { httpsCallable } from "firebase/functions";

export const processUserPrepayTickets = httpsCallable(functions, "processUserPrepayTickets");

export function processUserCode(code: string | null, t: TFunction<"translation", undefined>) {
  setTimeout(() => {
    processUserPrepayTickets({ code })
      .then((result) => {
        // console.log("processUserPrepayTickets--SUCCESS->", result)
        PopupService.setPopup(
          <>
            <p>{t("congratsNewPoints")}</p>
          </>
        );
      })
      .catch((error) => {
        console.log("processUserPrepayTickets--ERROR->", error);
        PopupService.setPopup(
          <div>
            <p className="text-red-800">
              {`${t("Sorry there was an error processing this code.")}`}
            </p>

            <SupportInfo />
          </div>
        );
      });
  }, 2000);
}
