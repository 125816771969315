import { Avatar, IconButton, Typography } from "@mui/material"

import AccountCircle from "@mui/icons-material/AccountCircle"
import LoginIcon from "@mui/icons-material/Login"
import React from "react"
import { useTranslation } from "react-i18next"
import { useUserStore } from "./components/stores/userStore"
import { auth } from "./firebase"
import { PopupService } from "./react-ui-components/components/PopupService"
import { Login } from "./components/LoginScreen/Login";

export const UserAvatar: React.FC = () => {
  const { user, isLoggedIn } = useUserStore()

  const { t } = useTranslation()

  if (!isLoggedIn) {
    return (
      <div className="flex flex-col gap-1 justify-center">
        <IconButton onClick={() => PopupService.setPopup(<Login />)}>
          <LoginIcon />
        </IconButton>
        <Typography>{t("Login")}</Typography>
      </div>
    )
  }

  // return <div>auth.currentUser?.photoURL {auth.currentUser?.photoURL}</div>
  const profileImageUrl = user?.profileImageUrl || auth.currentUser?.photoURL
  return profileImageUrl ? (
    <Avatar alt="Profile Image" src={profileImageUrl} />
  ) : (
    <AccountCircle sx={{ width: 56, height: 56 }} />
  )
}
