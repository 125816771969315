import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid2,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { signOut } from 'firebase/auth';
import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { auth } from '../firebase';
import { PopupService } from '../react-ui-components/components/PopupService';
import { getLocationStr } from '../utils';
import { useUserStore } from './stores/userStore';
import { UserPoints } from './UserPoints';
interface ProfileProps {
  onEdit: () => void;
}

const ViewProfile: React.FC<ProfileProps> = ({ onEdit }) => {
  const { user, deleteUser } = useUserStore();
  const { t } = useTranslation();
  const [authUser, loading] = useAuthState(auth);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // useEffect(() => {
  //   if (auth.currentUser) {
  //     deleteUser()
  //   }
  // }, [])

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // console.log("User logged out")
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error logging out: ', error);
      });
  };

  const handleDeleteAccount = async () => {
    const errorMessages: { [key: string]: string } = {
      'auth/requires-recent-login':
        'Please re-login before deleting your account for security reasons.',
      'auth/user-not-found': 'User not found. Please check your credentials.',
      'auth/too-many-requests': 'Too many requests. Please try again later.',
      'auth/network-request-failed':
        'Network error. Please check your internet connection and try again.',
      'auth/internal-error':
        'An internal error occurred. Please try again later.',
      // Add more mappings as needed
    };

    PopupService.setConfirm(t('confirmDeleteAccount'), async (val: any) => {
      if (/ok/i.test(val)) {
        if (auth.currentUser) {
          try {
            // await deleteUser(auth.currentUser)
            await deleteUser();
            PopupService.pushPopup(
              t('Your account has been deleted successfully.')
            );
            setTimeout(() => {
              PopupService.setPopup(null);
            }, 500);
          } catch (error: any) {
            const friendlyMessage =
              errorMessages[error.code] ||
              'An error occurred while deleting the account.';
            PopupService.pushPopup(t(friendlyMessage));
          }
        }
      }
    });
  };

  if (!user && !authUser) {
    return null;
  }

  if (loading) {
    return <div>{t('loading')}...</div>;
  }

  if (!user) {
    return (
      <Button variant="text" color="warning" onClick={handleLogout}>
        {t('profile.logout')}
      </Button>
    );
  }

  return (
    <UserCard
      onEdit={onEdit}
      user={user || authUser}
      isSmallScreen={isSmallScreen}
      handleDeleteAccount={handleDeleteAccount}
      handleLogout={handleLogout}
    />
  );
};

export default ViewProfile;

const Row = ({ label, value, type }: any) => {
  const { t } = useTranslation();
  return (
    <Grid2 container spacing={2}>
      <Grid2>
        <strong>{t(label)}:</strong>
      </Grid2>
      <Grid2>{type === 'boolean' ? t(value ? 'yes' : 'no') : value}</Grid2>
    </Grid2>
  );
};

function UserCard(props: any) {
  const { onEdit, user, isSmallScreen, handleDeleteAccount, handleLogout } =
    props;
  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <Card>
      <Box className="flex justify-center mt-2 position-relative">
        <Button
          onClick={onEdit}
          style={{
            position: 'absolute',
            left: '84%',
          }}
        >
          <EditIcon />
        </Button>
        <Avatar
          variant="circular"
          // component="img"
          src={user.profileImageUrl}
          alt={t('Profile')}
          sx={{
            width: isSmallScreen ? 100 : 140,
            height: isSmallScreen ? 100 : 140,
            borderRadius: '50%',
          }}
        />
      </Box>
      <UserPoints complete variant="text" className="w-full flex-wrap p-1" />
      <CardContent className="flex flex-col justify-center">
        <div className="m-5">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="flex justify-center"
          >
            {user.name}
          </Typography>
          <Typography
            variant="h6"
            color="text.secondary"
            className="flex justify-center"
          >
            {user.email}
          </Typography>
        </div>

        <Grid2 container spacing={2} direction="column">
          <Row label="phone" value={user.phoneNumber} />
          <Row label="address" value={getLocationStr(user.userAddress)} />
          <Row label="language" value={user.language} />
          {/* <Row label="location" value={user.location} /> */}
          <Row
            label="pushNotification"
            value={user.pushNotifications}
            type="boolean"
          />
          <Row label="currency" value={user.currency} />
          {/* <Grid item xs={6}>
  <Item>xs=6</Item>
</Grid>
<Grid item xs>
  <Item>xs</Item>
</Grid> */}
        </Grid2>
        {/*
        <Typography variant="h6" color="text.secondary">
          <strong>{t("phone")}:</strong> {user.phoneNumber}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          <strong>{t("address")}:</strong> {getLocationStr(user.userAddress)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>{t("language")}:</strong> {user.language}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>{t("location")}:</strong> {user.location}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>{t("pushNotification")}:</strong> {user.pushNotifications}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <strong>{t("currency")}:</strong> {user.currency}
        </Typography> */}
      </CardContent>
      {/* <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
          <Button variant="contained" color="primary" onClick={onEdit}>
            {t("profile.editProfile")}
          </Button>
        </Box> */}
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}
        className="gap-2"
      >
        <Button variant="text" color="error" onClick={handleDeleteAccount}>
          {t('profile.deleteAccount')}
        </Button>

        <Button variant="text" color="warning" onClick={handleLogout}>
          {t('profile.logout')}
        </Button>
      </Box>
    </Card>
  );
}
