import React from "react"
import { Logo } from "./components/Logo"
import { UserLocation } from "./components/UserLocation";

import Toolbar from "@mui/material/Toolbar"
import { UserPoints } from "./components/UserPoints"
import { UsersTickets } from "./components/UserTickets"

export const TopToolbar: React.FC = () => {
  return (
    <Toolbar>
      <div className="flex gap-1 justify-start w-full">
        <Logo width={100} cropped />
      </div>
      <div className="flex flex-col w-full gap-1 items-end">
        <UserLocation fullWidth textButton viewOnly color="secondary" />
        <div className="flex flex-row gap-1">
          <UsersTickets />
          <UserPoints />
        </div>
      </div>
    </Toolbar>
  )
}
