import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import InboxIcon from "@mui/icons-material/MoveToInbox"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MailIcon from "@mui/icons-material/Mail"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { TopMenuComponent } from "./TopMenuComponent"
import { DrawerContent } from "./DrawerContent"
import { styled } from "@mui/material"

const drawerWidth = 240

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window?: () => Window
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

export default function SidebarProvider(props: any) {
  const { window, children } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [isClosing, setIsClosing] = React.useState(false)

  const handleDrawerClose = () => {
    setIsClosing(true)
    setMobileOpen(false)
  }

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false)
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen)
    }
  }

  const drawer = (
    <div>
      {/* <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </DrawerHeader> */}
      <Divider />
      <Toolbar />
      <DrawerContent />
      {/* <Toolbar />
      <Divider />
      <DrawerContent /> */}
    </div>
  )

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <TopMenuComponent
        open={mobileOpen}
        drawerWidth={drawerWidth}
        handleDrawerOpen={handleDrawerToggle}
      />
      <Box
        component="nav"
        sx={{
          display: { sm: "block", xs: "none" },
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}

// import React, { createContext, ReactElement, useContext, useState } from "react"
// import Box from "@mui/material/Box"
// import Drawer from "@mui/material/Drawer"
// import { DrawerContent } from "./DrawerContent"
// import {
//   CssBaseline,
//   Divider,
//   IconButton,
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemIcon,
//   ListItemText,
//   styled,
//   Toolbar,
//   Typography,
//   useTheme,
// } from "@mui/material"
// import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar"
// import { ChevronLeft, ChevronRight, Inbox, Mail, Menu } from "@mui/icons-material"
// import { TopMenuComponent } from "./TopMenuComponent"

// export const SidebarContext = createContext(null)

// export const useSidebar = () => {
//   return useContext<{
//     isExpanded: boolean
//     setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
//     toggle: () => void
//   }>(SidebarContext as any)
// }

// export const SidebarProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
//   const [isExpanded, setIsExpanded] = useState(false)
//   function toggle() {
//     setIsExpanded(!isExpanded)
//   }
//   return (
//     <SidebarContext.Provider
//       value={
//         {
//           isExpanded,
//           setIsExpanded,
//           toggle,
//         } as any
//       }
//     >
//       {children}
//     </SidebarContext.Provider>
//   )
// }
// const drawerWidth = 240
// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
//   open?: boolean
// }>(({ theme }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(3),
//   transition: theme.transitions.create("margin", {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginLeft: 0,
//   justifyContent: "center",
//   /**
//    * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
//    * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
//    * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
//    * proper interaction with the underlying content.
//    */
//   position: "relative",
//   variants: [
//     {
//       props: ({ open }) => open,
//       style: {
//         transition: theme.transitions.create("margin", {
//           easing: theme.transitions.easing.easeOut,
//           duration: theme.transitions.duration.enteringScreen,
//         }),
//         marginLeft: drawerWidth,
//       },
//     },
//   ],
// }))

// interface AppBarProps extends MuiAppBarProps {
//   open?: boolean
// }

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
//   justifyContent: "flex-end",
// }))
// export const SidebarComponent: React.FC<{
//   drawerWidth: number
//   onIsExpanded?: (expanded: boolean) => void
//   children: any
// }> = ({ drawerWidth, onIsExpanded, children }) => {
//   const theme = useTheme()
//   const [open, setOpen] = React.useState(false)

//   const handleDrawerOpen: any = () => {
//     setOpen(true)
//   }

//   const handleDrawerClose = () => {
//     setOpen(false)
//   }

//   return (
//     <Box>
//       <CssBaseline />
//       <TopMenuComponent open={open} handleDrawerOpen={handleDrawerOpen} drawerWidth={drawerWidth} />

//       <Main open={open}>
//         {children}
//         {/* <DrawerHeader />
//         <Typography sx={{ marginBottom: 2 }}>
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
//           ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum
//           facilisis leo vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit
//           gravida rutrum quisque non tellus. Convallis convallis tellus id interdum velit laoreet id
//           donec ultrices. Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
//           adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra nibh cras.
//           Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Mauris commodo quis
//           imperdiet massa tincidunt. Cras tincidunt lobortis feugiat vivamus at augue. At augue eget
//           arcu dictum varius duis at consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem
//           donec massa sapien faucibus et molestie ac.
//         </Typography>
//         <Typography sx={{ marginBottom: 2 }}>
//           Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper eget nulla
//           facilisi etiam dignissim diam. Pulvinar elementum integer enim neque volutpat ac
//           tincidunt. Ornare suspendisse sed nisi lacus sed viverra tellus. Purus sit amet volutpat
//           consequat mauris. Elementum eu facilisis sed odio morbi. Euismod lacinia at quis risus sed
//           vulputate odio. Morbi tincidunt ornare massa eget egestas purus viverra accumsan in. In
//           hendrerit gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem et
//           tortor. Habitant morbi tristique senectus et. Adipiscing elit duis tristique sollicitudin
//           nibh sit. Ornare aenean euismod elementum nisi quis eleifend. Commodo viverra maecenas
//           accumsan lacus vel facilisis. Nulla posuere sollicitudin aliquam ultrices sagittis orci a.
//         </Typography> */}
//       </Main>

//       <Box role="presentation" onClick={() => setOpen(false)} onKeyDown={() => setOpen(false)}>
//         <Drawer
//           sx={{
//             width: drawerWidth,
//             flexShrink: 0,
//             "& .MuiDrawer-paper": {
//               width: drawerWidth,
//             },
//           }}
//           variant="persistent"
//           anchor="left"
//           open={open}
//         >
//           <DrawerHeader>
//             <IconButton onClick={handleDrawerClose}>
//               {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
//             </IconButton>
//           </DrawerHeader>
//           <Divider />
//           <DrawerContent />
//         </Drawer>
//       </Box>
//     </Box>
//   )
// }

// ------------------------------------------

// export const SidebarComponent: React.FC<{
//   drawerWidth: string | number
//   onIsExpanded?: (expanded: boolean) => void
// }> = ({ drawerWidth, onIsExpanded }) => {
//   const { isExpanded, setIsExpanded } = useSidebar()

//   const handleDrawerTransitionEnd = () => {
//     // setIsExpanded(false)
//     onIsExpanded && onIsExpanded(true)
//     console.log("handleDrawerTransitionEnd")
//   }

//   const handleDrawerClose = () => {
//     onIsExpanded && onIsExpanded(false)
//     setIsExpanded(true)
//     console.log("handleDrawerClose")
//   }

//   return (
//     <Box
//       component="nav"
//       sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
//       aria-label="mailbox folders"
//     >
//       <Drawer
//         variant="temporary"
//         open={isExpanded}
//         onTransitionEnd={handleDrawerTransitionEnd}
//         onTransitionEnter={()=>console.log('onTransitionEnter')}
//         onTransitionExited={()=>console.log('onTransitionExited')}
//         onClose={handleDrawerClose}
//         ModalProps={{
//           keepMounted: true, // Better open performance on mobile.
//         }}
//         sx={{
//           display: { xs: "block", sm: "none" },
//           "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
//         }}
//       >
//         <h1 className="float-right"> mobileOpen:{isExpanded + ""}</h1>
//         {/* <DrawerContent /> */}
//       </Drawer>
//       <Drawer
//        onClose={handleDrawerClose}
//         variant="permanent"
//         sx={{
//           display: { xs: "none", sm: "block" },
//           "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
//         }}
//         open={isExpanded}
//       >
//         <DrawerContent />
//       </Drawer>
//     </Box>
//   )
// }
