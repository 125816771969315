import { CityLocationSelector } from '../location/CityLocationSelector';
import { CountryLocationSelector } from '../location/CountryLocationSelector';
import LocationSelector from '../location/LocationSelector';
import { StateLocationSelector } from '../location/StateLocationSelector';
import React from 'react';

export const CountrySelectWidget =
  (context: any = null) =>
  ({ value, onChange, label }: any) => {
    // console.log("SelectWidget--rest->", label, rest)
    return (
      <CountryLocationSelector
        value={value}
        label={label}
        context={context}
        onChange={(n: any, v: any) => onChange(v)}
      />
    );
  };

export const StateSelectWidget =
  (context: any = null) =>
  ({ value, onChange, label, ...rest }: any) => {
    // console.log("SelectWidget--rest->", label, rest)
    return (
      <StateLocationSelector
        value={value}
        context={context}
        onChange={(n: any, v: any) => onChange(v)}
      />
    );
  };

export const CitySelectWidget =
  (context: any = null) =>
  ({ value, onChange, label }: any) => {
    return (
      <CityLocationSelector
        context={context}
        value={value}
        label={label}
        onChange={(n: any, v: any) => onChange(v)}
      />
    );
  };

export const LocationSelectWidget = ({ value, onChange, label }: any) => {
  return (
    <LocationSelector
      value={value}
      label={label}
      onChange={(n: any, v: any) => onChange(v)}
    />
  );
};
CountrySelectWidget.displayName = 'CountrySelectWidget';
StateSelectWidget.displayName = 'StateSelectWidget';
CitySelectWidget.displayName = 'CitySelectWidget';
LocationSelectWidget.displayName = 'LocationSelectWidget';
