import addressFormatter from '@fragaria/address-formatter';
import CountryData from 'country-data';
import { or, Timestamp, where } from 'firebase/firestore';
import json5 from 'json5';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import { useEffect, useState } from 'react';

export interface ICountryInfo {
  alpha2: string; // "DO",
  alpha3: string; // "DOM",
  countryCallingCodes: string[]; // ["+1 809", "+1 829", "+1 849"],
  currencies: string[]; // ["DOP"],
  emoji: string; // "🇩🇴",
  ioc: string; // "DOM",
  languages: string[]; // ["spa"],
  name: string; // "Dominican Republic",
  status: string; // "assigned",
}

export function getCountryInfo(code: string): CountryData.Country {
  // console.log("CountryData.countries----CountryData.countries[code]-->", CountryData.countries[code])
  /*
  { 
    "alpha2": "DO",
    "alpha3": "DOM",
    "countryCallingCodes": ["+1 809", "+1 829", "+1 849"],
    "currencies": ["DOP"],
    "emoji": "🇩🇴",
    "ioc": "DOM", 
    "languages": ["spa"],
    "name": "Dominican Republic",
    "status": "assigned"
  }
  */
  return CountryData.countries[code];
}

export function convertStringToNumber(
  stringNumber: number | string,
  screenHeight: number
) {
  const isString = typeof stringNumber === 'string';

  if (isString) {
    const reg: any = /^([+-]?(\d*\.)?\d+)(.*)/.exec(stringNumber);

    if (reg[3] === '%') {
      const h = reg[1];

      return screenHeight * (+h * 0.01);
    } else if (reg[3] === 'px') {
      const h = reg[1];

      return +h;
    }
  }
  if (stringNumber) {
    return +stringNumber;
  }

  return 0;
}

// export const formatCash = (n, showMinDigits) => {
//   n = +(n || 0)
//   if (n < 1e3) return n
//   if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K"
//   if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M"
//   if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B"
//   if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T"
// }

export const formatCash = (n: any, maxAbbreviateDigits = 3, precision = 1) => {
  n = +(n || 0);
  maxAbbreviateDigits = Number(`1e${maxAbbreviateDigits}`);
  if (n < maxAbbreviateDigits) return n.toLocaleString();
  const abbreviations = ['K', 'M', 'B', 'T'];
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6)
    return +(n / 1e3).toFixed(precision).toLocaleString() + abbreviations[0];
  if (n >= 1e6 && n < 1e9)
    return +(n / 1e6).toFixed(precision).toLocaleString() + abbreviations[1];
  if (n >= 1e9 && n < 1e12)
    return +(n / 1e9).toFixed(precision).toLocaleString() + abbreviations[2];
  if (n >= 1e12)
    return +(n / 1e12).toFixed(precision).toLocaleString() + abbreviations[3];

  // const abbreviations = ["", "K", "M", "B", "T"]
  // let index = 0

  // while (n >= 1e3 && index < abbreviations.length - 1) {
  //   n /= 1e3
  //   index++
  // }

  // const formattedNumber = n.toFixed(showMinDigits)
  // return `${formattedNumber}${abbreviations[index]}`
};

// TODO: implement
export const formatNumber = (
  number: string | number,
  locale: string = 'en-US'
) => {
  const options = { maximumSignificantDigits: 21, maximumFractionDigits: 21 };
  if (typeof number === 'string') {
    number = number || '0';

    let parsed = Number.parseFloat(number);
    parsed = isNaN(parsed) ? 0 : parsed;
    const result = parsed.toLocaleString(locale, options);

    return result;
  }

  return number.toLocaleString(locale, options);
};

/**
 * Hides content based on a property from an object.
 * @param {HTMLElement} element - The HTML element to hide.
 * @param {Object} item - The object containing the property to check.
 * @param {string|Function} property - The property name or a function that returns a boolean.
 * @returns {HTMLElement|null} - The hidden element or null if not hidden.
 */
// export const hide = (element, item, property) => {
//   if (!element || typeof element !== "object" || !item || typeof item !== "object") {
//     console.error("Invalid arguments. Please provide valid HTML element and object.")
//     return null
//   }

//   if (typeof property === "string") {
//     if (!Object.hasOwnProperty.call(item, property)) {
//       console.error(`Object does not have the property: ${property}`)
//       return null
//     }

//     return item[property] ? element : null
//   } else if (typeof property === "function") {
//     return property(item) ? element : null
//   } else {
//     console.error("Invalid property parameter. Please provide a valid property name or function.")
//     return null
//   }
// }

/**
 * Returns an array with unique elements filtered by the given function.
 * @param {Array} a - The input array.
 * @param {Function} [f] - The function to filter the array.
 * @returns {Array} - The filtered array.
 */
// export function uniq(a: any, f = (item) => item) {
//   const seen = {}
//   return a.filter(function (item) {
//     const val = f(item)
//     return Object.hasOwnProperty.call(seen, val) ? false : (seen[val] = true)
//   })
// }

// export function uniq(a) {
//   const seen = {}
//   return a.filter(function (item) {
//     return Object.hasOwnProperty.call(seen, item)
//       ? false
//       : (seen[item] = true)
//   })
// }

export const removeUndefinedValues = (obj: any) => {
  // Base case: if the current object is not an object, return it
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  return JSON.parse(JSON.stringify(obj));

  // If the current object is an array, map over its elements and recursively remove undefined values
  if (Array.isArray(obj)) {
    return obj.map(removeUndefinedValues);
  }

  // If the current object is an object, iterate over its properties
  Object.keys(obj).forEach((key) => {
    // Recursively remove undefined values from inner objects
    obj[key] = removeUndefinedValues(obj[key]);

    // If the property value is undefined, delete the property
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });

  return obj;
};

export function getNullForUndefinedReplacer(key: any, value: any) {
  const valType = typeof value;

  if (valType === 'undefined') {
    return null;
  }

  return value;
}

// TODO: TypeError: cyclical structure in JSON object
// export function getCircularReplacer() {
//   const ancestors: any = []
//   return function (key, value: any) {
//     const valType = typeof value

//     if (valType === "undefined") {
//       return null
//     }

//     if (valType !== "object" || value === null) {
//       return value
//     }
//     // `this` is the object that value is contained in,
//     // i.e., its direct parent.
//     const _t: any = this as any
//     while (ancestors.length > 0 && ancestors.at(-1) !== _t) {
//       ancestors.pop()
//     }
//     if (ancestors.includes(value)) {
//       return "[Circular]"
//     }
//     ancestors.push(value)
//     return value
//   }
// }

export const getCircularReplacer2 = () => {
  const seen = new WeakSet();

  return (key: any, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }

    return value;
  };
};
// TODO: Fix
// export const stringify = (obj: any) => {
//   return JSON.stringify(obj, getCircularReplacer())
// }

export function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; // Time in microseconds since page-load or 0 if unsupported

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }

    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function getFileNameId(name: string, add = '') {
  return (name || generateUUID()).toLowerCase().replace(/ /g, '_') + add;
}

export function parse(obj: any, defaultValue: any = {}) {
  if (!obj) {
    return defaultValue;
  }
  if (typeof obj === 'string') {
    obj = obj.replace(/“|”|‘|’/g, '"');
    try {
      return json5.parse(obj);
    } catch (error) {
      console.log('WARNING: ERROR_PARSING_OBJECT', {
        error,
        obj,
        defaultValue,
      });

      return defaultValue;
    }
  }

  return obj;
}

export const reject = (error: any) => Promise.reject(new Error(error));

export const cleanObject: any = (obj: any, removeCallback?: any) =>
  Object.entries(obj).reduce((acc, [k, v]) => {
    if (removeCallback && removeCallback(k)) {
      return acc;
    }

    return v === undefined ? acc : { ...acc, [k]: v };
  }, {});

export function getExtension(path: string) {
  const baseName: any = path.split(/[\\/]/).pop(); // extracts file name from full path

  // (supports separators `\\` and `/`)
  const pos = baseName.lastIndexOf('.'); // gets the last position of `.`

  if (baseName === '' || pos < 1) {
    // if the file name is empty or ...
    return ''; // the dot not found (-1) or comes first (0)
  }

  return baseName.slice(pos + 1); // extracts extension ignoring "."
}

export interface UploadResult {
  downloadUrl?: string;
  path?: string;
  fileName?: string;
  originalUri?: string;
  imageSource?: string;
}

export interface UploadRequest {
  setUploading?: (val: boolean) => void;
  setTransferred?: (val: number) => void;
  setError?: (error: any) => void;
  location: string;
  fileName?: string;
  imageSource: string;
  updated?: boolean;
}

export function matchRule(
  str: string,
  rules: any,
  getMatchedItems?: boolean
): boolean | any[] {
  str = Array.isArray(str) && str.length ? str[0] : str;
  rules = !Array.isArray(rules) ? [rules] : rules;

  const matchedItems: any = [];
  for (let index = 0; index < rules.length; index++) {
    let rule = rules[index];

    if (typeof rule === 'string') {
      rule = rule.replace(/\s/gi, '*');
    } else {
      rule = rule.key.replace(/\s/gi, '*');
    }

    // console.log('rule------->', rule);
    const escapeRegex = (str: string) =>
      str.replace(/([.*+?^=!:${}()|\[\]\/\\])/gi, '\\$1');
    const regStr = rule.split('*').map(escapeRegex).join('.*?');
    // console.log('regStr------->', regStr);

    const reg = new RegExp(regStr, 'g');
    const cleanStr = str.split(/\s+/).join('\\s+');

    const result = reg.test(cleanStr);

    if (result) {
      if (getMatchedItems) {
        const item = rules[index];
        const found = matchedItems.find((x: any) => {
          return typeof x === 'string' ? x === item : x.key === item.key;
        });
        // console.log('item------->', item);
        // console.log('found------->', found);
        // console.log('matchedItems----->', matchedItems);

        if (!found) {
          if (typeof item === 'string') {
            matchedItems.push({
              key: item,
              value: item,
            });
          } else {
            // const valuePattern = item.valuePattern.split("*").map(escapeRegex).join(".");
            const regValStr = `${regStr}.*?(${item.valuePattern})`;
            // console.log('cleanStr-----regValStr---->', regValStr);
            // console.log('cleanStr-----cleanStr---->', cleanStr);
            const regVal = new RegExp(regValStr, 'ig');
            const regResultValues: any = regVal.exec(cleanStr);
            // console.log('cleanStr-----regVal-->', regResultValues);
            matchedItems.push({
              key: item.key,
              value: regResultValues?.index > 1 ? regResultValues[1] : '',
            });
          }
        }
      } else {
        return result;
      }
    }
  }

  return getMatchedItems ? matchedItems : !!matchedItems.length;
}

export function getSingleItem(data: any, defaultVal: any = ''): any {
  return Array.isArray(data) && data?.length > 0 ? data[0] : data || defaultVal;
}

export function getPoints(
  price: number,
  pointsConfiguration: {
    type: 'percent' | '1to1' | 'pointPerValue'; // 1to1, pointPerValue
    value: number; // 1 percent
    points: number;
    valueAfterPoints: number;
    upTo: number; // up to this value above this value it will use upToValue
    upToValue: number;
  }
) {
  switch (pointsConfiguration.type) {
    case 'percent':
      if (pointsConfiguration.upTo) {
        return price >= pointsConfiguration.upTo
          ? price * (pointsConfiguration.upToValue * 0.01)
          : price * (pointsConfiguration.value * 0.01);
      } else {
        return price * (pointsConfiguration.value * 0.01);
      }
    case '1to1':
      if (pointsConfiguration.upTo) {
        return price >= pointsConfiguration.upTo
          ? price * (pointsConfiguration.upToValue * 0.01)
          : price * (pointsConfiguration.value * 0.01);
      } else {
        return price;
      }
    case 'pointPerValue': // like every 1000 equals 1 point
      if (price <= 0) {
        return 0;
      }

      if (pointsConfiguration.upTo && price >= pointsConfiguration.upTo) {
        return Math.floor(
          (price / pointsConfiguration.upToValue) * pointsConfiguration.points
        );
      } else {
        return Math.floor(
          (price / pointsConfiguration.value) * pointsConfiguration.points
        );
      }
    default:
      return 0;
  }
}

export function randomNumber(props: any) {
  let { min, max } = props;
  min = min || 99999;
  max = max || 10000;
  const random = Math.floor(Math.random() * (max - min + 1)) + min;

  return random;
}

export function getAddressFormat(value: any, multiLine = false) {
  if (!value) {
    return '';
  }
  // const countryLookup = CountryData.lookup.countries({ countries: "US"})
  const countryCode = value.country === 'DR' ? 'DO' : value.country;
  // console.log("countryLookup------->", JSON.stringify(CountryData.countries[countryCode]))
  // {
  //   alpha2: "DO",
  //   alpha3: "DOM",
  //   countryCallingCodes: ["+1 809", "+1 829", "+1 849"],
  //   currencies: ["DOP"],
  //   emoji: "🇩🇴",
  //   ioc: "DOM",
  //   languages: ["spa"],
  //   name: "Dominican Republic",
  //   status: "assigned",
  // }
  const countryLookup = CountryData.countries[countryCode];
  const formatted = addressFormatter.format({
    houseNumber: value.houseNumber,
    road: value.street,
    // "neighbourhood": "Crescent Park",
    city: value.city === '*' ? '' : value.city,
    postcode: value.postalcode,
    county: value.county,
    state: value.province === '*' ? '' : value.province,
    country: value.country,
    countryCode: countryLookup?.alpha2 || value.country,
  });

  return multiLine ? formatted : formatted.split('\n').join(' ');
}

export function getLocationStr(loc: any, t?: any): any {
  if (!loc) {
    return '';
  }

  const street = loc.street && loc.street !== '*' ? `${loc.street} ` : '';
  const city = loc.city && loc.city !== '*' ? `${`${loc.city}, `}` : '';
  const province = loc.province && loc.province !== '*' ? loc.province : '';
  const county =
    loc.county && loc.county !== '*'
      ? province
        ? `, ${loc.county} `
        : ` ${loc.county} `
      : '';
  let country = loc.country && loc.country !== '*' ? loc.country : '';

  country = province ? ` (${country})` : country;
  // console.log("street || city || county || province -------->", { street, city, county, province })
  return street || city || county || province || country
    ? `${street}${city}${province}${county}${country}`
    : t
      ? t('any')
      : '*';
}

export function toJSRecursive(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    // Base case: return non-objects as is
    return obj;
  }

  if (Array.isArray(obj)) {
    // If obj is an array, recursively convert each element
    return obj.map((item) => toJSRecursive(item));
  }

  // If obj is an object, recursively convert each property
  const newObj: any = {};
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      newObj[key] = toJSRecursive(obj[key]);
    }
  }

  return newObj;
}

export function matchTwoLocations(a: any, b: any) {
  if (!a || !b) {
    // console.log("Both items must be valid objects----->", a, "---", b)
    return true;
  }

  a = resetLocationModel(a);
  b = resetLocationModel(b);

  const countryMatch =
    a.country === '*' || b.country === '*'
      ? true
      : a.country.search(
          new RegExp(b.country.replace(/[^a-zA-Z 0-9]+/g, ''), 'i')
        ) === 0;

  const provinceMatch =
    a.province === '*' || b.province === '*'
      ? true
      : a.province.search(
          new RegExp(b.province.replace(/[^a-zA-Z 0-9]+/g, ''), 'i')
        ) === 0;

  const cityMatch =
    a.city === '*' || b.city === '*'
      ? true
      : a.city.search(
          new RegExp(b.city.replace(/[^a-zA-Z 0-9]+/g, ''), 'i')
        ) === 0;

  return countryMatch && provinceMatch && cityMatch;
}

export function resetLocationModel(loc: any): any {
  loc = {
    province: '*',
    city: '*',
    country: '*',
    ...loc,
  };

  return loc;
}

export function isMap(map: any) {
  if (
    map &&
    typeof map.clear === 'function' &&
    typeof map.delete === 'function' &&
    typeof map.get === 'function' &&
    typeof map.has === 'function' &&
    typeof map.set === 'function'
  ) {
    return true;
  }

  return false;
}

function preloadImage(src: any) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
    (window as any)[src] = img;
  });
}

export function useImagePreloader(imageList: string[]) {
  const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      // console.log("PRELOAD")
      try {
        if (isCancelled) {
          return;
        }

        const imagesPromiseList: Promise<any>[] = [];
        for (const i of imageList) {
          if (!i) {
            continue;
          }
          imagesPromiseList.push(preloadImage(i));
        }

        await Promise.all(imagesPromiseList);

        if (isCancelled) {
          return;
        }

        setImagesPreloaded(true);
      } catch (error) {
        console.log('image preloading error----->', error);
      }
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [imageList]);

  return { imagesPreloaded };
}

export const toAddressFormat = (val: any) => {
  return getAddressFormat(val);
};

export const countriesDropdown = (availableLocationsConfig: any, t?: any) => {
  const location = availableLocationsConfig;
  if (!location) {
    return [];
  }

  const obj = isMap(location) ? Object.fromEntries(location) : location;

  return ['*', ...Object.keys(obj)].map((k) => {
    const countryInfo = getCountryInfo(k);

    return {
      label: countryInfo?.name
        ? `${countryInfo.emoji} ${countryInfo.name}`
        : k === '*'
          ? t
            ? t('any')
            : k
          : k,
      value: k,
    };
  });
};

export const locationsMatched = (a: any, b: any) => {
  return matchTwoLocations(a, b);
};

export const provincesDropdown = (
  availableLocationsConfig: any,
  country: string,
  t?: any
) => {
  if (!country || country === '*') {
    return [];
  }

  const location = availableLocationsConfig;
  if (!location) {
    return [];
  }

  const obj = isMap(location) ? Object.fromEntries(location) : location;

  const provinces = isMap(obj[country])
    ? Object.fromEntries(obj[country])
    : obj[country];

  if (!provinces) {
    return [];
  }

  return ['*', ...Object.keys(provinces)].map((k) => ({
    label: k === '*' ? (t ? t('any') : 'Any') : k,
    value: k,
  }));
};

export const citiesDropdown = (
  availableLocationsConfig: any,
  country: string,
  province: string,
  t?: any
) => {
  if (!country || country === '*') {
    return [];
  }
  const location = availableLocationsConfig;
  if (!location) {
    return [];
  }

  const obj = isMap(location) ? Object.fromEntries(location) : location;

  const provinces = isMap(obj[country])
    ? Object.fromEntries(obj[country])
    : obj[country];

  if (!province || province === '*') {
    return [];
  }
  if (!provinces) {
    return [];
  }

  const cities = provinces[province] || [];

  return ['*', ...cities].map((k) => ({
    label: t && k === '*' ? t('any') : k,
    value: k,
  }));
};

export interface LocationType {
  country?: string;
  province?: string;
  city?: string;
}

export function getLocationQuery(
  location: LocationType,
  expirationDate: boolean = true
) {
  const arr: any[] = [
    where('disabled', '==', false),
    expirationDate
      ? where('expirationDate', '>=', Timestamp.now().toMillis())
      : null,

    !location.country
      ? null
      : where('location.country', 'in', ['', '*', location.country]),

    // where("location.country", "==", "*"),
    // where("location.country", "==", location.country),
    !location.province
      ? null
      : or(
          where('location.province', '==', ''),
          where('location.province', '==', '*'),
          where('location.province', '==', location.province)
        ),

    !location.city
      ? null
      : or(
          where('location.city', '==', ''),
          where('location.city', '==', '*'),
          where('location.city', '==', location.city)
        ),
  ];

  return arr.filter((x: any) => !!x);
}

export const phoneNumberMatch = (
  phoneNumber1: string,
  phoneNumber2: string,
  countryCode: CountryCode = 'US'
) => {
  // Parse the phone number based on the country code
  const parsedPhoneNumber1 = parsePhoneNumberFromString(
    phoneNumber1,
    countryCode
  );
  const parsedPhoneNumber2 = parsePhoneNumberFromString(
    phoneNumber2,
    countryCode
  );

  // If parsing fails, parsedPhoneNumber will be undefined
  if (!parsedPhoneNumber1) {
    return { match: false, message: 'Invalid phone number 1 format.' };
  }

  if (!parsedPhoneNumber2) {
    return { match: false, message: 'Invalid phone number 2 format.' };
  }

  // Check if the phone number is valid
  const isValid1 = parsedPhoneNumber1.isValid();
  const isValid2 = parsedPhoneNumber2.isValid();

  if (!isValid1 && !isValid2) {
    return { match: false, message: 'Invalid phone number 1 and 2.' };
  }

  // Optionally, format the phone number to international format
  const formattedNumber1 = parsedPhoneNumber1.formatInternational();
  const formattedNumber2 = parsedPhoneNumber2.formatInternational();

  return {
    match: formattedNumber1 === formattedNumber2,
    formattedNumber1,
    formattedNumber2,
  };
};

export const validatePhoneNumber = (
  phoneNumber: string,
  countryCode: CountryCode = 'US'
) => {
  // Parse the phone number based on the country code
  const parsedPhoneNumber = parsePhoneNumberFromString(
    phoneNumber,
    countryCode
  );

  // If parsing fails, parsedPhoneNumber will be undefined
  if (!parsedPhoneNumber) {
    return { valid: false, message: 'Invalid phone number format.' };
  }

  // Check if the phone number is valid
  const isValid = parsedPhoneNumber.isValid();

  if (!isValid) {
    return { valid: false, message: 'Invalid phone number.' };
  }

  // Optionally, format the phone number to international format
  const formattedNumber = parsedPhoneNumber.formatInternational();

  return {
    valid: true,
    message: 'Valid phone number.',
    formattedNumber,
  };
};
