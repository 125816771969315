import { doc, getDoc } from "firebase/firestore"
import { useEffect, useState } from "react"
import { auth, db } from "../firebase"

import { useAuthState } from "react-firebase-hooks/auth"
import { LoadingService } from "../react-ui-components/components/LoadingService"
import { LocationContext } from "../react-ui-components/components/location/LocationSelector"
import { ILocationItem } from "../react-ui-components/components/models/ILocation"

export const LocationService = {
  setLocation: (location: any) => console.log("LocationService--location-->", location),
  getLocation: (): any => console.log("getLocation---->"),
  setAvailableLocations: (val: any) => console.log("setAvailableLocations---->"),
}

export interface ICountry {
  currency: string
  disabled: boolean
  display: string
  icon: string
  key: string
}
export interface ILocation {
  [key: string]: {
    [key: string]: string[]
  }
}
export const DEFAULT_COUNTRY = {
  currency: "USD",
  disabled: false,
  display: "United States",
  icon: "https://firebasestorage.googleapis.com/v0/b/winfacil-250ad.appspot.com/o/assets%2Fus-flag.jpeg?alt=media&token=b86edf51-856a-458f-b491-8f05bd979e7e",
  key: "US",
}

export const DEFAULT_AVAILABLE_LOCATION = {
  US: {
    Florida: ["Orlando"],
    "New York": ["Queens"],
  },
}

const val: {
  location: string
  availableLocations: ILocation
} = {
  location: DEFAULT_COUNTRY.key,
  availableLocations: DEFAULT_AVAILABLE_LOCATION,
}

const getAvailableLocations = async (setData: any, setLoading: any) => {
  LoadingService.setLoading(true)
  setLoading(true)
  try {
    const querySnapshot = doc(db, "app-configurations/availableLocations")

    const ref = await getDoc(querySnapshot)
    const data = ref.data()
    setData && setData(data)
    return data
  } catch (error) {
    return {}
  } finally {
    LoadingService.setLoading(false)
    setLoading(false)
  }
}

export const LocationProvider: React.FC<{
  children: any
  defaultLocation?: any
}> = (props) => {
  const { children, defaultLocation = DEFAULT_AVAILABLE_LOCATION } = props
  const [availableLocations, setAvailableLocations] = useState<ILocation>(
    DEFAULT_AVAILABLE_LOCATION,
  )
  const [loading, setLoading] = useState(false)
  const [user] = useAuthState(auth)
  const savedLOC = JSON.parse(
    localStorage.getItem("WINLOC") ||
      JSON.stringify({
        country: "US",
        city: "",
        state: "",
      }),
  )
  const [currentLocation, setCurrentLocation] = useState<ILocationItem>(savedLOC)

  useEffect(() => {
    LocationService.setLocation = setCurrentLocation
    LocationService.setAvailableLocations = setAvailableLocations
    user && getAvailableLocations(setAvailableLocations, setLoading)
  }, [user])

  useEffect(() => {
    LocationService.getLocation = () => currentLocation
    localStorage.setItem("WINLOC", JSON.stringify(currentLocation))
  }, [currentLocation])

  return (
    <LocationContext.Provider
      value={{
        availableLocations,
        currentLocation,
        setCurrentLocation,
        setAvailableLocations,
        loading,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}
