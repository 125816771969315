import { Button } from "@mui/material"
import React, { useEffect, useState } from "react"

import Profile from "./components/Profile"
import { useUserStore } from "./components/stores/userStore"
import { auth } from "./firebase"
import { PopupService } from "./react-ui-components/components/PopupService"
import { colors } from "./theme"
import { UserAvatar } from "./UserAvatar"
import { Login } from "./components/LoginScreen/Login"
import { useAuthState } from "react-firebase-hooks/auth"

export const UserQuickInfo: React.FC = () => {
  const { user, isLoggedIn } = useUserStore()
  // const [userInfo] = useAuthState(auth)

  return (
    <Button
      className="flex flex-row gap-1"
      onClick={() => PopupService.setPopup(isLoggedIn ? <Profile /> : <Login />)}
    >
      <UserAvatar />
      {!isLoggedIn ? null : (
        <div style={{ textWrap: "nowrap", width: 180 }}>
          <p className="text-ellipsis overflow-hidden">
            {user?.displayName || user?.name || auth.currentUser?.displayName}
          </p>
          <p
            className="text-ellipsis overflow-hidden"
            style={{
              fontSize: 10,
              color: colors.textDim,
              lineHeight: 1,
            }}
          >
            {user?.email ||
              user?.phoneNumber ||
              auth.currentUser?.email ||
              auth.currentUser?.phoneNumber}
          </p>
        </div>
      )}
    </Button>
  )
}
