import { IconButton } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';

import { YouTube } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { EmptyComponent } from '../../react-ui-components/components/EmptyComponent';
import { IVideoData } from '../../react-ui-components/components/Video/IVideoData';
import VideoPlayer from '../../react-ui-components/components/Video/VideoPlayer';

export const VideoButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <IconButton
      onClick={() => {
        navigate('/videos');
      }}
    >
      <YouTube color="secondary" />
    </IconButton>
  );
};
const Videos: React.FC = () => {
  const [videosData, setVideosData] = useState<IVideoData[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const docRef = collection(db, 'videos'); // Update with your document ID
        const docSnap = await getDocs(docRef);
        setVideosData(docSnap.docs.map((x) => x.data() as IVideoData));
      } catch (error) {
        console.error('Error fetching videos data:', error);
      }
    };

    fetchVideos();
  }, []);

  if (!videosData?.length) {
    return loading ? (
      <div className="text-center text-accent">{t('Loading')}...</div>
    ) : (
      <EmptyComponent message={t('Coming soon...')} />
    );
  }

  return (
    <div className="flex flex-col gap-5 items-center">
      {videosData.map((d: IVideoData, i: number) => (
        <VideoPlayer video={d} key={i} />
      ))}
    </div>
  );
};

export default Videos;
