import React from "react"
import { UserLocationSelector } from "./components/UserLocation"

import { HomeLink } from "./HomeLink"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import { MyLocationIcon } from "./components/CountryCityStateFetcher"
import QRCodeScanner from "./components/QRCodeScanner"
import Toolbar from "@mui/material/Toolbar"
import { VideoButton } from "./components/video/Videos"
import { PopupService } from "./react-ui-components/components/PopupService"

export const BottomToolbar: React.FC<{
  handleDrawerOpen?: React.MouseEventHandler<HTMLButtonElement>
  open: boolean
}> = ({ handleDrawerOpen, open }) => {
  return (
    <Toolbar>
      <div className="flex flex-row gap-1 mx-5 justify-between w-full">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerOpen}
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        >
          <MenuIcon />
        </IconButton>
        <HomeLink iconOnly />
        <QRCodeScanner />
        <VideoButton />
        <MyLocationIcon onClick={() => PopupService.setPopup(<UserLocationSelector />)} />
      </div>
    </Toolbar>
  )
}
