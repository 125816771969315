import React, { useEffect, useState } from 'react';

import { httpsCallable } from 'firebase/functions';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { QrCode } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

import { auth, functions } from '../firebase';
import { LoadingService } from '../react-ui-components/components/LoadingService';
import { Image } from "./Tickets/Image";
import htmr from "htmr";


const generateQRCode = httpsCallable(functions, 'generateTicketTransferQRCode');

const TransferCodeCard = (props: any) => {
  const { ticket, onUpdateQRCode } = props;
  const {
    code,
    createdDate,
    raffleType,
    disabled,
    expirationDate,
    qrCodeDataUrl: qrCodeDataUrlOR,
  } = ticket;
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState(qrCodeDataUrlOR);
  const [open, setOpen] = useState(false);
  const [user] = useAuthState(auth);
  const { t } = useTranslation();

  useEffect(() => {
    async function init() {
      if (!user) {
        return;
      }
      if (!qrCodeDataUrlOR) {
        try {
          LoadingService.setLoading(true);
          const result: any = await generateQRCode({
            id: ticket.id,
            fromUserId: user.uid,
            raffleId: ticket.raffleId,
          });
          onUpdateQRCode && onUpdateQRCode(result.data.qrCode);
          setQrCodeDataUrl(result.data.qrCode);
        } catch (error) {
        } finally {
          LoadingService.setLoading(false);
        }
      } else {
        setQrCodeDataUrl(qrCodeDataUrlOR);
      }
    }
    init();
  }, [qrCodeDataUrlOR, ticket, user]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <div className="mb-4">
        <Typography variant="h6" component="div">
          {t('Transfer Code')}: <span className="text-blue-600">{code}</span>
        </Typography>
      </div>
      <div className="mb-2">
        <Typography variant="body1" component="div">
          {t('Raffle')}: {htmr(raffleType)}
        </Typography>
      </div>
      <div className="mb-2">
        <Typography variant="body1" component="div">
          {t('Date')}: {new Date(expirationDate).toLocaleString()}
        </Typography>
      </div>
      {/* <div className="mb-2">
        <Typography variant="body1" component="div">
          Disabled:{" "}
          <span className={disabled ? "text-red-600" : "text-green-600"}>
            {disabled ? "Yes" : "No"}
          </span>
        </Typography>
      </div> */}
      <div className="text-center mt-4">
        <Button
          variant="contained"
          color="primary"
          startIcon={<QrCode />}
          onClick={handleClickOpen}
          disabled={disabled}
        >
          {t('View Transfer Code')}
        </Button>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogTitle>{t('QR Code')}</DialogTitle>
        <DialogContent>
          <div className="flex justify-center">
            <Image src={qrCodeDataUrl} alt="QR Code" />
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

export default TransferCodeCard;
