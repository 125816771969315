import {
  and,
  collection,
  doc,
  onSnapshot,
  or,
  query,
  where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import Carousel from 'react-material-ui-carousel';
import { auth, db } from '../../firebase';
import { LoadingService } from '../../react-ui-components/components/LoadingService';
import { useLocation } from '../../react-ui-components/components/location/LocationSelector';
import { parse, useImagePreloader } from '../../utils';
import { AdItem } from './AdItem';
// import { useTranslation } from "react-i18next"

// import { useTranslation } from "react-i18next"

const listenForAdsConfigChanges = function (setData: any) {
  LoadingService.setLoading(true);
  const querySnapshot = doc(db, 'app-configurations/adsConfig');

  return onSnapshot(querySnapshot, {
    next: (data) => {
      setData(data.data());
      LoadingService.setLoading(false);
    },
    error: (data) => {
      LoadingService.setLoading(true, data?.message || data, true);
    },
    complete: () => {
      LoadingService.setLoading(false);
    },
  });
};

const listenForAdsDataChanges = function (setData: any, location: any) {
  LoadingService.setLoading(true);
  // console.log("listenForAdsDataChanges---location--->", location)
  let arr: any[] = [
    and(where('location.country', '==', location?.country || 'US')),
  ];

  location?.state &&
    (arr = [
      and(
        or(
          where('location.province', '==', location.state),
          where('location.province', '==', '*')
        ),
        where('location.country', '==', location.country || 'US')
      ),
    ]);

  location?.city &&
    (arr = [
      and(
        or(
          where('location.city', '==', location.city),
          where('location.city', '==', '*')
        ),
        where('location.country', '==', location.country || 'US'),
        location.state
          ? where('location.province', '==', location.state)
          : or(
              where('location.province', '==', ''),
              where('location.province', '==', '*')
            )
      ),
    ]);

  const querySnapshot = query(collection(db, 'ads'), ...arr);

  return onSnapshot(querySnapshot, {
    next: (snap) => {
      const arr: any[] = [];
      snap.forEach((data) => arr.push(data.data()));
      setData(arr);
      LoadingService.setLoading(false);
    },
    error: (data) => {
      LoadingService.setLoading(true, data?.message || data, true);
    },
    complete: () => {
      LoadingService.setLoading(false);
    },
  });
};

export function AdsList(): any {
  const [adsConfig, setAdsConfig] = useState({
    maxWidth: '100%',
    borderStyle: '{ borderColor: "#ddd", borderWidth: 1}',
    maxHeight: '25%',
    showAdOnHome: true,
    disabled: false,
    id: 'adsConfig',
    adsLocation: 'TOP',
    maxHeightOR: '70%',
    stickyAds: false,
    adMaxTime: 6,
  });
  const [mainStyle, setMainStyle] = useState({});
  const [adsData, setAdsData] = useState([]);
  const [user] = useAuthState(auth);

  // useEffect(() => {}, [LocationService.getLocation()])

  useEffect(() => {
    const style = parse(adsConfig?.borderStyle, {
      borderColor: '#ddd',
      borderWidth: 1,
    });
    setMainStyle({
      borderStyle: 'solid',
      ...style,
      position: 'relative',
    });
  }, [adsConfig]);

  const { imagesPreloaded } = useImagePreloader(
    adsData?.map((x: any) => x.img)
  );
  const { currentLocation: location } = useLocation();

  useEffect(() => {
    if (user) {
      return listenForAdsConfigChanges(setAdsConfig);
    }
  }, [location, user]);

  useEffect(() => {
    if (user) {
      return listenForAdsDataChanges(setAdsData, location);
    }
  }, [location, user]);

  // const { t } = useTranslation()

  if (!imagesPreloaded) {
    return null;
  }
  if (adsConfig?.disabled || !adsConfig?.showAdOnHome) {
    return null;
  }

  if (!adsData?.length) {
    return null;
  }

  return (
    <div style={mainStyle}>
      <Carousel
        cycleNavigation
        fullHeightHover
        indicators={false}
        navButtonsAlwaysVisible={false}
        autoPlay={true}
        animation="slide"
        swipe
        interval={adsConfig.adMaxTime * 1000}
        stopAutoPlayOnHover
      >
        {adsData.map((item: any, i) => (
          <AdItem
            {...item}
            key={i}
            maxHeight={adsConfig?.maxHeight}
            maxWidth={adsConfig?.maxWidth}
          />
        ))}
      </Carousel>
    </div>
  );
}
