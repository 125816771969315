
import React, { createContext, ReactElement, useContext, useState } from "react"

export const SidebarContext = createContext(null)

export const useSidebar = () => {
  return useContext<{
    isExpanded: boolean
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
    toggle: () => void
  }>(SidebarContext as any)
}

export const SidebarProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  function toggle() {
    setIsExpanded(!isExpanded)
  }
  return (
    <SidebarContext.Provider
      value={
        {
          isExpanded,
          setIsExpanded,
          toggle,
        } as any
      }
    >
      {children}
    </SidebarContext.Provider>
  )
}