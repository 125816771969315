import { User, signOut } from 'firebase/auth';
import {
  Timestamp,
  and,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { DateTime } from 'luxon';
import { auth, db as firestore } from '../../firebase';
import { LocationType, getLocationQuery } from '../../utils';

import { ISupportInfo } from './ISupportInfo';
import { IUser } from './IUser';
import { ISponsor } from './Sponsor';

// import { OffersConfig } from "./OffersConfig"

import { createTheme } from '@mui/material';
import { LoadingService } from '../../react-ui-components/components/LoadingService';
import { PopupService } from '../../react-ui-components/components/PopupService';
import { createContext, useContext } from "react";
import { Ticket } from "../Tickets";

const colors = {
  primary: {
    main: '#04975C',
  },
  secondary: {
    main: '#edf2ff',
  },
};
export const darkTheme = createTheme({
  palette: {
    mode: 'dark', //"light", //
    ...colors,
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light', //
    ...colors,
  },
});

class UserStore {
  points: number = 0;
  usersCount: number = 0;
  ticketsCount: number = 0;
  usersCountIcon: any = {};
  supportInfo: ISupportInfo = {};
  userRolesCache: any = null;
  user: IUser | null | undefined = null;
  loading: boolean = true;
  isLoggedIn: boolean = false;
  sponsors: any[] = [];
  displayContentConfig: any = {};
  theme: any = lightTheme;
  mode: string = 'light';
  isEmailProvider: boolean = false;
  userRoles: any[] = [];
  userIsAdvanced: boolean = false;
  setMode: any = null;
  loadUser: any = null;
  updateUser: any = null;
  deleteUser: any = null;
  setPoints: any = null;
  addSponsor: any = null;
  listenForSponsorsChanges: any = null;
  resetSponsor: any = null;
  getThisMonthOffers: any = null;
  usertickets: Ticket[] = [];
}

/* getThisMonthOffers(sponsorId: string) {
    try {
      const currentMonth = DateTime.now().toFormat("LLyyyy")
      // console.log('getThisMonthOffers ----currentMonth------->', currentMonth)
      // console.log('getThisMonthOffers ----date------->', date)
      // console.log('getThisMonthOffers ----sponsorId------->', sponsorId)
      // console.log('currentMonth---->', currentMonth);
      const ref = query(
        collection(firestore, "sponsors", sponsorId, currentMonth, "offers"),
        orderBy("order"),
        where("disabled", "==", false),
      )

      // const found = self.sponsors.find(x => x.id === id);
      // const data = result.data()
      const arr = []
      result.forEach((element) => {
        const data = element.data()
        // console.log("DATA---->", data)
        arr.push(data)
      })

      if (!date) {
        self.thisMonthOffers = cast(arr)
      }

      return arr
    } catch (error) {
      console.log("There was an error trying to load sponsors offers", error)
    } finally {
      self.loading = false
    }
  }
*/

export const INITIAL_USER_CONTEXT_VALUE = new UserStore();
export const UserContext = createContext(INITIAL_USER_CONTEXT_VALUE);

export const useUserStore = () => useContext(UserContext);
