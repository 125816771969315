import { Button, Icon } from '@mui/material';

import { toJS } from 'mobx';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { PopupService } from '../react-ui-components/components/PopupService';
import { formatCash } from '../utils';
import { useUserStore } from './stores/userStore';
import Tickets from './Tickets';
import { Login } from "./LoginScreen/Login";

export interface UsersCounterProps {
  style?: any;
}

export const UsersTickets = function UsersCounter(props: UsersCounterProps) {
  const { isLoggedIn, ticketsCount } = useUserStore();

  const { style } = props;
  const $styles = { ...$container, ...style };
  
  const iconDefault: any = {
    name: 'book_online',
    isFont: true,
    isFa5: true,
    style: { marginLeft: 1 },
    size: 'medium',
    isUrl: false,
  };

  const [user] = useAuthState(auth);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <Button
      variant="contained"
      style={$styles}
      className="flex flex-row gap-1/2 p-1"
      startIcon={
        <Icon fontSize={iconDefault.size} style={toJS(iconDefault.style)}>
          {iconDefault.name}
        </Icon>
      }
      onClick={() => {
        isLoggedIn
          ? PopupService.setPopup(<Tickets />, '', {
              maxWidth: '100%',
              width: '100%',
              height: '100%',
            })
          : PopupService.setPopup(<Login />);
      }}
    >
      {formatCash(ticketsCount)}
    </Button>
  );
};

const $container: any = {
  justifyContent: 'center',
  flexDirection: 'row',
};
